import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import * as querybase from 'querybase';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/Services/common.service';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { LivebiddingService } from 'src/app/Services/livebidding.service';
import { isNullOrUndefined } from 'util';
import { Subject } from 'rxjs/Subject';
import { TestBed } from '@angular/core/testing';
import { NgxWheelComponent } from 'ngx-wheel';
import { CountdownConfig } from 'ngx-countdown';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { writeSync } from 'fs';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { IpaddressService } from 'src/app/Services/ipaddress.service';
import { HostListener } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { fromEvent, merge, of } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { Guid } from 'guid-typescript';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

declare let $: any;

@Component({
  selector: 'app-live-bidding',
  templateUrl: './live-bidding.component.html',
  styles: []
})

export class LiveBiddingComponent implements OnInit {
  online$ = merge(
    of(navigator.onLine),
    fromEvent(window, 'online').pipe(mapTo(true)),
    fromEvent(window, 'offline').pipe(mapTo(false))
  );
  stopInterval: boolean=false;
  chatreference = firebase.database().ref('chats/')
  chathistoryform: FormGroup;
  ChitNo: any;
  loading: boolean
  eventvalue: any;
  subscriberdetails: any;
  auctionerdetails: any= [];
  formvalue: any;
  numbertoadd: any;
  groupcode: any;
  chitvalue: any;
  den1: any;
  den2: any;
  den3: any;
  den4: any;
  den5: any;
  den6: any;
  den7: any;
  den8: any;
  den9: any;
  den10: any;
  den11: any;
  maxticketno: any;
  maxdiscountticketno:any;
  bidamount: any;
  auctionstartdate: any;
  auctionenddate: any;
  auctionstarttime: any;
  auctionendtime: any;
  currentDatetime: any
  idToLandOn: any;
  meetingid: any;
  autionhall: any;
  auctioneer: any;
  auctioneername:any;
  drawticketno: any;
  onlineparticipants: any;
  circlemover: any;
  walkinparticipants: any;
  successclient:any;
  bidpayble:any;
  key:any;
  maxamount: any;
  usertype: any;
  tickets: any;
  ticketlength: number;
  drawnumber: any;
  username: any;
  auctioneercomments: any;
  chatdata:any;

  branchid: any;
  onlineparticipantslength: any;
  walkinparticipantslength: any;
  amount: any;
  textboxvalue: any;
  branchdata: any;
  mobile:any;
  today: any;
  countdowntime: any = 0;
  diffcountdowntime: any = 0;
  maxdiscountallowed: any = 0
  LeftTime: any = 0;
  addingnumber: any = ""
  details: any=[]
  array: any = []
  ticketdetails:any=[]
  drawticketnos:any=[]
  unique = []
  chats = [];
  finalChat=[];
  auctioneerdata=[];
  index1:number=0;
  index2:number=0;
  tabIndex: number;
  participantscount: number=0;
  participantscountfirbase: number=0;

  scrolltop: number = null;
  spinnercalled: boolean = false
  disabledforButtons: boolean = false
  spinnerLoadingstatus:boolean=false;
  showcomments:boolean=false
  showbadge:boolean=false
  button: boolean;
  showticketno:boolean=false
  WindowCloseStatus:boolean=false
  time: string;
  branchname:any;
  fixedtime: string;
  isloadingcheck = false;

  @ViewChild('chatcontent', { static: false }) chatcontent: ElementRef;
  prettyConfig: CountdownConfig = { leftTime: 0, format: 'm:ss', prettyText: (text) => { return text }, };
  ipAddress: any;
  ticketno: any;
  brachname: any;
  auctionmonth: any;
  enterredvalue: any;
  isConnected = true;
  nowtime: string;
  session: any;
  maxtime: string;

    constructor(private router: Router, private commonservice: CommonService,
    private formBuilder: FormBuilder, public datepipe: DatePipe,
    private toastr: ToastrService, private activatedroute: ActivatedRoute,
    private livebiddingservice: LivebiddingService,private ip:IpaddressService,
    private hallallotmentservice:HallAllotmentService,
    private http:HttpClient,private connectionService: ConnectionService) {
    /////////////  Get Chat details  /////////////////////////////////////
    this.GetChatDatabyMeetingid();
    //////////////  Get Participants details /////////////////////////////
    this.Getparticipants();
    ///////////show spinner/////////////////////////
   // this.ShowSpinnerpopup();
    ///////////show success/////////////////////////
   // this.ShowSuccesspopup();

  }
  @HostListener('window:beforeunload', ['$event'])
    public doSomething($event) {
        console.log("do I see this?") // <---- this logs to the console.
        return this.WindowCloseStatus;
    }
  ngOnInit() {
    this.chathistoryform = this.formBuilder.group({
      'amount': [null]
    });
    this.branchdata=this.hallallotmentservice.GetBranchDetails();
    this.http.get('http://api.ipify.org/?format=json')
    .subscribe((res: any) => 
    {
        debugger
      this.commonservice.IpAddress = res.ip;
    sessionStorage.setItem("ipaddress", res.ip);
      console.log("Ip-In-LOGIN",res.ip)
    });
    this.activatedroute.queryParams.subscribe(params => { this.ChitNo = params['id']; });
    this.DisableFields(false)
   // this.GetIpaddress();
    this.spinnercalled = false
    if (this.ChitNo != null) {
    this.usertype = 'online-client'
    this.showcomments=false
    this.showticketno=true
    this.getsubscriberdetails(this.ChitNo);
    (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = true;
    }
    else 
    {  
      this.showticketno=false
      this.showcomments=this.branchdata.pType=='reviewer'?false:true;
      this.ticketdetails = this.livebiddingservice.GetTicketDetails();
      this.groupcode=this.branchdata.pType=='reviewer'?this.livebiddingservice.GetReviwerGroup():this.ticketdetails.groupcode;
      console.log(this.groupcode);
      if(this.branchdata.pType!='reviewer'){
      let data=this.auctionerdetails;
      data.groupcode=this.ticketdetails.groupcode;
      data.status='Y';
      var sessionid=Guid.create().toString();
      this.session=sessionid; 
      data.sessionid=sessionid;
      const newMessage1 = firebase.database().ref('auctioneer/').push();
      newMessage1.set(data);
      }
      this.GetGroupAuctionDetails();
    }
    let maxamountarray = this.chats.filter(item => item.biddamount == this.maxamount && item.meetingId == this.meetingid)
    this.unique = [...new Set(maxamountarray.map(item => item.ticketNo))];
  }
//   GetChatDatabyMeetingid(){
//     let amount=0;
//     try{ 
//     if(!isNullOrUndefined(this.meetingid)){
//       //firebase.database().ref('chats/').orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
//      this.chatreference.orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
//         debugger;
//         this.chats = [];
//         this.chats = this.commonservice.ConvertfirebaseDataToArray(resp);
//         this.chats = this.chats.filter(item => item.meetingId == this.meetingid);
//         this.maxamount = this.chats.length > 0 ? Math.max(...this.chats.map(({ biddamount }) => biddamount)) : 0;
//         if (this.chats.length > 0) {
//           // | sortingPipe:'biddamount'
//           this.chats=this.chats.sort((a, b) => {
//             return (b.biddamount-a.biddamount);
//           });
// //           var objDiv = <HTMLInputElement>document.getElementById("bidscroll");
// // objDiv.scrollTop = objDiv.scrollHeight;
//       //    this.chats.sort((a, b) =>(<any>new Date(a.livedate) ><any>new Date(b.livedate) ? -1 : 1));
// //this.chats.sort((a, b) => 0 - (a > b ? -1 : 1));

//           let maxamountarray = this.chats.filter(item => item.biddamount == this.maxamount && item.meetingId == this.meetingid);
//           this.unique = [...new Set(maxamountarray.map(item => item.ticketNo))];
//           console.log("unique", this.unique)
//           this.drawticketnos=this.unique
//           this.ticketlength=this.unique.length
//           if (this.unique.length == 1) {
//             this.drawticketno = this.unique[0]
//           }
 
//         }
//         this.maxticketno = this.chats.filter(s => s.biddamount == this.maxamount)[0].ticketNo;
//         amount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);
//         if(amount<=this.maxamount)
//         {
  
//           this.chathistoryform.controls.amount.setValue(this.maxamount==0?
//             this.commonservice.currencyformat(this.bidamount):this.commonservice.currencyformat(this.maxamount)); 
//           }  
//       });

     
      
//     }
 
//   }
//   catch (e) {
//     console.log("firebase error on Chats",e);
//   }
//   }
GetChatDatabyMeetingid(){
  try{ 
  if(!isNullOrUndefined(this.meetingid) && this.LeftTime>=0 && this.countdowntime>=0){
    //firebase.database().ref('chats/').orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
   this.chatreference.orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
      debugger;
      this.chats = [];
      this.chats = this.commonservice.ConvertfirebaseDataToArray(resp);
      this.chats = this.chats.filter(item => item.meetingId == this.meetingid);
      this.maxamount = this.chats.length > 0 ? Math.max(...this.chats.map(({ biddamount }) => biddamount)) : 0;
      if (this.chats.length > 0) {
        // | sortingPipe:'biddamount'
        this.chats=this.chats.sort((a, b) => {
          return (b.biddamount-a.biddamount);
        });
//           var objDiv = <HTMLInputElement>document.getElementById("bidscroll");
// objDiv.scrollTop = objDiv.scrollHeight;
    //    this.chats.sort((a, b) =>(<any>new Date(a.livedate) ><any>new Date(b.livedate) ? -1 : 1));
//this.chats.sort((a, b) => 0 - (a > b ? -1 : 1));

        let maxamountarray = this.chats.filter(item => item.biddamount == this.maxamount && item.meetingId == this.meetingid);
        this.unique = [...new Set(maxamountarray.map(item => item.ticketNo))];
        console.log("unique", this.unique)
        this.drawticketnos=this.unique
        this.ticketlength=this.unique.length
        if (this.unique.length == 1) {
          this.drawticketno = this.unique[0]
        }
    
      }
      this.maxticketno = this.chats.filter(s => s.biddamount == this.maxamount)[0].ticketNo;
      if(this.usertype=='walkin-client')
      {
        this.chathistoryform.controls.amount.setValue(this.maxamount==0?
          this.commonservice.currencyformat(this.bidamount):this.commonservice.currencyformat(this.maxamount));        
      }
      else
      {
        let amount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);
        if(amount<=this.maxamount)
        {
          this.chathistoryform.controls.amount.setValue(this.maxamount==0?
            this.commonservice.currencyformat(this.bidamount):this.commonservice.currencyformat(this.maxamount));          }
      }

      // let amount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);
      // if(amount>this.maxamount)
      // {
  
      // }
      // else
      // {
      //   this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.maxamount));
      // } 
    });
    
  }

}
catch (e) {
  console.log("firebase error on Chats",e);
}
}
  Getparticipants() {
try{
    if(!isNullOrUndefined(this.meetingid)){
    firebase.database().ref('userinfo/').orderByChild('meetingId').equalTo(this.meetingid).on('value', snapshot => {
      let unserinfodata = [];
      unserinfodata = snapshot.exists() ? this.commonservice.ConvertfirebaseDataToArray(snapshot) : [];
      unserinfodata =unserinfodata.filter(item => item.meetingId == this.meetingid);   
  unserinfodata =unserinfodata.filter(
  (thing, i, arr) => arr.findIndex(item => item.chitNo === thing.chitNo && item.meetingId === this.meetingid) === i
); 
      this.participantscount = unserinfodata.length;  
      this.onlineparticipants = !isNullOrUndefined(this.meetingid) ? unserinfodata.filter(item => item.meetingId == this.meetingid && item.subscribertype == "online client") : [];
      this.walkinparticipants = !isNullOrUndefined(this.meetingid) ? unserinfodata.filter(item => item.meetingId == this.meetingid && item.subscribertype == "walkin client") : [];
      console.log("online", this.onlineparticipants)
      console.log("walkin", this.walkinparticipants)
      this.onlineparticipantslength= this.onlineparticipants.length
      this.walkinparticipantslength= this.walkinparticipants.length
    });
  }
}
catch (e) {
  console.log("firebase error on Participants",e);
}
  }
  AddMaxDiscount()
  {
    this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.maxdiscountallowed))  
  }
  getMaxamount(){
    try{
     
      if(!isNullOrUndefined(this.meetingid) && this.LeftTime>=0 && this.countdowntime>=0)
      {
        this.chatreference.orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
      debugger;
      let data=[];
      data = this.commonservice.ConvertfirebaseDataToArray(resp);
      data = this.chats.filter(item => item.meetingId == this.meetingid);
      this.maxamount = this.chats.length > 0 ? Math.max(...this.chats.map(({ biddamount }) => biddamount)) : 0;
      if(this.usertype=='walkin-client')
      {
      this.chathistoryform.controls.amount.setValue(this.maxamount==0?
      this.commonservice.currencyformat(this.bidamount):this.commonservice.currencyformat(this.maxamount));       
      }
      else
      {
        let amount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);

        if(amount<=this.maxamount)
        {
          this.chathistoryform.controls.amount.setValue(this.maxamount==0?
            this.commonservice.currencyformat(this.bidamount):this.commonservice.currencyformat(this.maxamount));  
        }
      }
       
    });
  }
  }
  catch (e) {
    console.log("firebase error on get  Max amount ",e);
  }
  }
  GetGroupAuctionDetails()
  {
    if(this.branchdata.pType!='reviewer'){
      this.stopInterval=true;
      this.timer();
    }
    let branchname=this.branchdata.pType=='reviewer'?this.livebiddingservice.GetReviwerbranch():this.branchdata.branchname;
    this.livebiddingservice.GetGroupAuctionDetails(this.groupcode,branchname).subscribe(json=>
    {
      debugger;
        if(json)
        {           
          (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = false
          this.DisableFields(true)
          this.details=json
          console.log("GroupAuctionDetails",this.details)
          this.usertype = 'walkin-client' ;
          if (this.details != undefined) { 
            this.groupcode = this.details.groupcode;
            this.chitvalue = this.details.chitvalue;
            this.auctionstarttime = this.details.auctionstartTime
            this.auctionendtime = this.details.auctionendTime
            this.auctionstartdate = this.details.auctionstartDate
            this.auctionenddate = this.details.auctionendDate
            this.currentDatetime=this.details.currentdatettime
            this.meetingid = this.details.meetingId
            this.auctioneer = this.details.auctioneer    
            this.username=this.details.auctioneer
            this.autionhall = this.details.auctionHall
            this.maxdiscountallowed = this.details.maxdiscountamt
            this.bidamount = this.details.mindiscountamt
            this.branchname = this.details.branchname
            this.branchid = this.details.branchId
            this.auctionmonth=this.details.auctmonth
            this.maxdiscountticketno=this.details.maxticketno
            let date=this.datepipe.transform(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata"}),'yyyy-MM-dd')
            let time=this.datepipe.transform(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata"}),'HH:mm');
           
          // let ipaddress1=this.ipAddress;
           if(this.branchdata.pType!='reviewer'){
            this.ticketdetails.checkeditems.filter(function (df) { df.logindate =date ,df.lgintime=time,
            df.auctionstartDate=json["auctionstartDate"],df.auctionendDate=json["auctionendDate"],
            df.auctionstartTime=json["auctionstartTime"],df.auctionendTime=json["auctionendTime"]});
            console.log("checkedtimes after save",this.ticketdetails.checkeditems)   
        firebase.database().ref('dueParticipants/').orderByChild('meetingId').equalTo(this.meetingid).once('value', snapshot => {
          debugger;
          let unserinfodata=[];
          unserinfodata=snapshot.exists()?this.commonservice.ConvertfirebaseDataToArray(snapshot):[];
          unserinfodata = unserinfodata.filter(item => item.meetingId == this.meetingid)
        for(let i=0;i<this.ticketdetails.checkeditems.length;i++)
        {
          let checkuserExist=unserinfodata.length>0?unserinfodata.filter(s=>s.ticketNo==this.ticketdetails.checkeditems[i].ticketNo):[];
          if(checkuserExist.length==0){
            const newMessage = firebase.database().ref('dueParticipants/').push();
           // const newMessages = firebase.database().ref.child("Victor").setValue("setting custom key when pushing new data to firebase database");
            newMessage.set(this.ticketdetails.checkeditems[i]);
            }
        }
        }); 
      }
      else{
        (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = true;
          this.DisableFields(true)
      }
            
            this.getMaxamount(); 
            this.GetCountdowntime();
            this.GetChatDatabyMeetingid();
            this.Getparticipants();
            this.getdenominations(this.chitvalue); 
           // this.ShowSpinnerpopup();
           // this.ShowSuccesspopup();
          }   
      
        }
    })
  }
  getsubscriberdetails(Chitno) {
    this.livebiddingservice.GetSubscriberDetails(Chitno).subscribe(json => {
     console.log("IP Address",this.commonservice.IpAddress);
      this.subscriberdetails = json;
      console.log("subscriberdetails", this.subscriberdetails)
      this.auctionstartdate = this.subscriberdetails.auctionstartDate
      this.auctionenddate = this.subscriberdetails.auctionendDate
      this.auctionstarttime = this.subscriberdetails.auctionstartTime
      this.auctionendtime = this.subscriberdetails.auctionendTime
      this.currentDatetime=this.subscriberdetails.currentdatettime
      this.meetingid = this.subscriberdetails.meetingId
      this.username = this.subscriberdetails.subscriberName
      this.auctioneer=this.subscriberdetails.auctioneer
      this.autionhall = this.subscriberdetails.auctionHall
      this.maxdiscountallowed = this.subscriberdetails.maxdiscountamt
      this.bidamount = this.subscriberdetails.mindiscountamt
      this.maxdiscountticketno=this.subscriberdetails.maxticketNo
      this.branchname = this.subscriberdetails.branch
      this.auctionmonth=this.subscriberdetails.auctmonth
      this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.bidamount));
      console.log("auctionstarttime", this.subscriberdetails)
      this.showbadge=true
      this.ticketno=this.subscriberdetails.ticketNo
      if (!isNullOrUndefined(this.subscriberdetails.ticketNo)) {
        debugger;
        this.groupcode = this.subscriberdetails.groupcode;
        this.chitvalue = this.subscriberdetails.chitvalue;
      
        ///////////// check subscriber  exist or not///////////////////////
        firebase.database().ref('userinfo/').orderByChild('logindate').equalTo(this.datepipe.transform(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata"}), 'yyyy-MM-dd')).once('value', snapshot => {
          debugger;
          let unserinfodata = [];
          unserinfodata = snapshot.exists() ? this.commonservice.ConvertfirebaseDataToArray(snapshot) : [];
          unserinfodata = unserinfodata.filter(item => item.meetingId == this.meetingid)
          let checkuserExist = unserinfodata.length > 0 ? unserinfodata.filter(s => s.ticketNo == this.subscriberdetails.ticketNo) : [];    
          if (checkuserExist.length !== 0)
          {
          this.participantscount = unserinfodata.length;
          }
          if (checkuserExist.length == 0) {
            this.subscriberdetails.logindate = this.datepipe.transform(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata"}), 'yyyy-MM-dd');
            this.subscriberdetails.logintime = this.datepipe.transform(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata"}), 'HH:mm');
            this.subscriberdetails.subscribertype = 'online client'
            this.subscriberdetails.IpAddress=this.commonservice.IpAddress;
            const newMessage = firebase.database().ref('userinfo/').push();
            newMessage.set(this.subscriberdetails);
          }
        });
      }
      this.getMaxamount();
      this.GetCountdowntime();
      this.GetChatDatabyMeetingid();
      this.Getparticipants();
      this.getdenominations(this.chitvalue);
    //  this.ShowSpinnerpopup();
     // this.ShowSuccesspopup();
      })
  }
  timer(){
   if (this.stopInterval)
    {
          setInterval(() => {   
          this.getcount();
    }
    ,500);
  }
}
confirmsBox() {
  const confirmBox = new ConfirmBoxInitializer();
  confirmBox.setTitle('Are you sure?');
  confirmBox.setMessage('Confirm to delete user: John Doe!');
  // Set button labels, the first argument for the confirmation button, and the second one for the decline button.
  confirmBox.setButtonLabels('YES', 'NO');
  
  confirmBox.setConfig({
      DisableIcon: true, // optional
      AllowHTMLMessage: false, // optional
      ButtonPosition: 'center', // optional
      // Evoke the confirmation box with predefined types.
      LayoutType: DialogLayoutDisplay.DANGER // SUCCESS | INFO | NONE | DANGER | WARNING
  });
  
  // Simply evoke the popup and listen which button is clicked.
  const subscription = confirmBox.openConfirmBox$().subscribe(resp => {
      // IConfirmBoxPublicResponse
      console.log('ConfirmBox button response: ', resp);
      subscription.unsubscribe();
  });
}
getcount()
{
  firebase.database().ref('auctioneer/').orderByChild('groupcode').equalTo(this.groupcode).on('value', snapshot => {
  let unserinfodata = [];
  let userauctiodata=[];
   unserinfodata = snapshot.exists() ? this.commonservice.ConvertfirebaseDataToArray(snapshot) : [];  
   userauctiodata= snapshot.exists() ? this.commonservice.ConvertfirebaseDataToArray(snapshot) : [];  
   unserinfodata =unserinfodata.filter(item => item.groupcode == this.groupcode && item.status=='Y' && item.sessionid==this.session); 
   userauctiodata=userauctiodata.filter(item => item.groupcode == this.groupcode && item.status=='Y');
   if(unserinfodata.length==0||userauctiodata.length>1)
   { 
        this.WindowCloseStatus=true; 
        window.close();   
   }
  });
}
cleardenominatins()
{
  this.den1="";
  this.den2="";
  this.den3="";
  this.den4="";
  this.den5="";
  this.den6="";
  this.den7="";
  this.den8="";
  this.den9="";
  this.den10="";
  this.den11="";
}
  getdenominations(CHITVALUE)
  {
    this.cleardenominatins();
    this.livebiddingservice.Getdenominations(CHITVALUE).subscribe(json=>{
     this.den1=json["den1"];
     this.den2=json["den2"];
     this.den3=json["den3"];
     this.den4=json["den4"];
     this.den5=json["den5"];
     this.den6=json["den6"];
     this.den7=json["den7"];
     this.den8=json["den8"];
     this.den9=json["den9"];
     this.den10=json["den10"];
     this.den11=json["den11"];

    })

  }
  participantchange(dataitem, event) {
  
    if (event.currentTarget.checked) {
      this.subscriberdetails = dataitem;
      this.subscriberdetails.subscribertype = 'walkin client';
      this.DisableFields(false)
    }
  }

  DisableFields(disabled) {
   
    (<HTMLInputElement>document.getElementById("myFieldset")).disabled = disabled;
    (<HTMLInputElement>document.getElementById("disableamount")).disabled = disabled;
    (<HTMLInputElement>document.getElementById("disableclearbutton")).disabled = disabled;
    (<HTMLInputElement>document.getElementById("disableenterbutton")).disabled = disabled;

  }
 
  Addamount() {
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );
    this.online$.subscribe((isOnline) =>{
      if(isOnline) {
        if(!isNullOrUndefined(this.meetingid) && this.LeftTime>=0 && this.countdowntime>=0){
        let amount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);
        if(this.chats.length>0){
        let existlength= this.chats.filter(item=>(item.biddamount==amount) && amount!=this.maxdiscountallowed).length;
       if(existlength==0)
       {   
       this.BeforeAddingToGrid()
       }
       else{
        this.toastr.warning("Amount is Already Exist..", "Warning")
       }
    }
    else if(amount==this.bidamount)
    {  
      this.toastr.warning("Amount Should Be Greater Than Maximum Bid Offer", "Warning")
    }
    else{
      this.BeforeAddingToGrid()
    }
  }

        } else {
          this.subscriberdetails=[];
    this.DisableFields(true);
    this.confirmBox();

        }
    });  
  }

  BeforeAddingToGrid()
  {   
    if(!isNullOrUndefined(this.meetingid) && this.LeftTime>=0 && this.countdowntime>=0){  
    let amount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);
    if (amount < this.bidamount) 
    {
      if(amount < this.maxamount)
      {
        this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.maxamount))   
      }
      else{
        this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.bidamount))
      }
      this.toastr.warning("Amount Should Not Be Less Than Bid Amount..", "Warning")
      this.clearnumbers()
    }
    else if (amount < this.maxamount) {
      this.toastr.warning("Amount Should Not Be Less Than Max Amount..", "Warning")
      this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.maxamount))
      this.clearnumbers()
      
    }
    else if (amount > this.maxdiscountallowed) {
      this.toastr.warning("Amount Should Not Be Greater Than Max Discount Allowed..", "Warning")
      this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.maxamount))
      this.clearnumbers()
  
    }
    else
    {
      let tickentnolength=this.chats.length>0? this.chats.filter(item=>item.ticketNo==this.subscriberdetails.ticketNo && item.biddamount==amount).length:0
     if(tickentnolength==0)
     {
      this.CheckMaxAmountBeforeAdding()
     }
    else{
      this.toastr.warning("Ticket No Already Exist on Max Bid Amount..", "Warning")
      this.clearnumbers()
    }
    }
  }
  
  }
  CheckMaxAmountBeforeAdding()
  {
   
  this.online$ = merge(
  of(navigator.onLine),
  fromEvent(window, 'online').pipe(mapTo(true)),
  fromEvent(window, 'offline').pipe(mapTo(false))
);
this.online$.subscribe((isOnline) =>{
  if(isOnline) {
    if(!isNullOrUndefined(this.meetingid) && this.LeftTime>=0 && this.countdowntime>=0){
      if(this.LeftTime<=300 && this.countdowntime>=0)
      {
    let data = this.subscriberdetails;
    if(data.ticketNo!=undefined)
    {

    data.livedate = this.datepipe.transform(new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata"}), 'dd/MM/yyyy');
    data.livetime = this.datepipe.transform(this.nowtime, 'hh:mm:ss a');
    data.meetingId = this.meetingid;
    data.biddamount = this.commonservice.removeCommasForEntredNumber(this.chathistoryform.controls.amount.value);
    const newMessage = firebase.database().ref('chats/').push();
    newMessage.set(data);
    this.clearnumbers()
    let maxamount = Math.max(...this.chats.map(({ biddamount }) => biddamount));
    this.amount=""
    }
  }
  else
  {
    (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = true;

  }
  }
  else
  {
    (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = true;

  }
    
  } else {  
    this.subscriberdetails=[];
    this.DisableFields(true);
    this.confirmBox();
  }
});
 
    //this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(maxamount))
  }
  clearnumbers() {
    debugger
    this.addingnumber = ""
  }
  clearbutton()
  {
    this.addingnumber="";
    this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(this.maxamount))
  }
  closemodal()
  {
    this.WindowCloseStatus=true;
    window.close();
  }
  SaveData(successbiddetails)
  {
    if(!isNullOrUndefined(this.meetingid) && this.LeftTime==0 && this.countdowntime==0){
    firebase.database().ref('auctioneer/').orderByChild('groupcode').equalTo(this.groupcode).on('value', resp => {
      let auctioneerlogindata = [];
      auctioneerlogindata = this.commonservice.ConvertfirebaseDataToArray(resp);
      auctioneerlogindata = auctioneerlogindata.filter(item => item.groupcode == this.groupcode);
      this.auctioneerdata=auctioneerlogindata;
    });
    firebase.database().ref('userinfo/').orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
      
      let data1 = [];
      data1 = resp.exists() ? this.commonservice.ConvertfirebaseDataToArray(resp) : [];
      data1 =data1.filter(item => item.meetingId == this.meetingid);   
      data1 =data1.filter(
  (thing, i, arr) => arr.findIndex(item => item.chitNo === thing.chitNo && item.meetingId === this.meetingid) === i
); 
this.participantscountfirbase = data1.length; 
    });
     let chathistoryData={chatdata:this.finalChat,participantdata:this.onlineparticipants}; 
     let auctioneerhistorydata={auctioneerdata:this.auctioneerdata};
     this.chatdata={branch:this.branchname,groupcode:this.groupcode,auctionendDate:this.auctionstartdate,
      auctionTime:this.auctionstarttime,auctionHall:this.autionhall,
      auctioneer:this.auctioneer,auctionnumber:this.auctionmonth,meetingId:this.meetingid,
      prizedTicket:this.drawticketno,bidoffer:this.maxamount,subscribername:this.successclient,
      mobile:this.mobile,chitvalue:this.chitvalue,bidpayable:this.bidpayble,
      auctionercomment:isNullOrUndefined(this.auctioneercomments)?"":this.auctioneercomments,
      chathistory: JSON.stringify(chathistoryData),particapants:this.participantscountfirbase,ahistory:JSON.stringify(auctioneerhistorydata)}
      let data=JSON.stringify(this.chatdata)
      this.livebiddingservice.SaveLiveAuctionHistory(data).subscribe(json=>{
    debugger;
     if(json)
     {  
       console.log("after saving",json)
       $("#successpopmodal").modal('show');
       const newMessage = firebase.database().ref('successfullbid/').push();
       newMessage.set(successbiddetails);  
       setTimeout(()=>{  
       this.WindowCloseStatus=true; 
       window.close(); 
      },30000)        
     }
  })  
}  
  }
  AuctioneerComments(event)
  {
    debugger
    
    this.auctioneercomments=event.target.value
  }
  Calculation(event)
  {
    debugger



    var lastDigit = String(this.chathistoryform.controls.amount.value).substr(-3);
    let lastdigits = parseInt(lastDigit)
    console.log("lastDigit", lastdigits);
    var x = lastdigits;
    var y = 100;
    var z = x % y;
    console.log("z", z)
    if (z == 0) {
      
    }
    else {
      this.chathistoryform.controls.amount.setValue("");
      this.commonservice.showWarningMessage("Amount should be multilpes of 100")  
    }

  }
  AfterDraw(event) {
        if(this.usertype=='walkin-client')
        {
          let successbiddetails:any=[];
          this.drawticketno = event
         // let data1=this.walkinparticipants.filter(item=>item.meetingId==this.meetingid && item.ticketNo==this.drawticketno)
          let data2=this.onlineparticipants.filter(item=>item.meetingId==this.meetingid && item.ticketNo==this.drawticketno)
          // if(data1.length>0){
          //   successbiddetails=data1;
          // }
           if(data2.length>0){
            successbiddetails=data2;
          }
          successbiddetails[0].drawticketno=this.drawticketno;
          successbiddetails[0].prizedAmount=this.maxamount;
          successbiddetails[0].biddamount=this.maxamount;
          this.successclient=successbiddetails[0].subscriberName;
          this.mobile=successbiddetails[0].mobileNO;
          this.bidpayble=successbiddetails[0].chitvalue-this.maxamount;
        //  let data={drawticketno:this.drawticketno}
        if(this.branchdata.pType!='reviewer'){
          this.SaveData(successbiddetails[0]);
         firebase.database().ref('ShowSpinner/').child(this.key).remove();
        }
      } 
  }
  UpdateChathistory(){
    let comments=isNullOrUndefined(this.auctioneercomments)?"":this.auctioneercomments;
    let groucode=this.groupcode;
    let meetingid=this.meetingid;
    this.livebiddingservice.UpdateLiveAuctionHistory(groucode,meetingid,comments).subscribe(json=>{
      if(json)
      {
        $("#successpopmodal").modal('hide');
        this.WindowCloseStatus=true;
        window.close();
      }
   })
  }
  callspinner() {
    debugger;
    this.spinnercalled = true;
    this.livebiddingservice.setDrawticketnumbers(this.unique);
    this.usertype=this.ChitNo != null?'online-client':'walkin-client';
    $("#confirmationmodal").modal('hide');
    this.usertype=this.ChitNo != null?'online-client':'walkin-client';
    if(this.usertype=='walkin-client'){
      this.idToLandOn = this.unique[Math.floor(Math.random() * this.unique.length)];
    }
    let data={meetingId:this.meetingid,spinnercalled:this.spinnercalled,startspin:false,isActive:true,idToLandOn:this.idToLandOn}
    const newMessage = firebase.database().ref('ShowSpinner/').push();
    newMessage.set(data);  
  }
  GetIpaddress(){
    this.commonservice.getIPAddress().subscribe((res: any) => {
      debugger
      let data3 = res;
     console.log("venu testggg",data3.ip)
      this.ipAddress=data3.ip
    }, (error) => {
      this.commonservice.showErrorMessage(error);
    });
  }
  AddingNumbers(event) {



    debugger
    this.numbertoadd = event.target.text.replace('+ ', '').trim()
    this.addingnumber += this.numbertoadd + "+";
   this.maxamount=this.maxamount==0?this.bidamount:this.maxamount;
   this.textboxvalue=isNullOrEmptyString(this.chathistoryform.controls.amount.value)?this.maxamount:this.chathistoryform.controls.amount.value;
    let b =isNullOrEmptyString(this.amount)? this.maxamount:this.textboxvalue;
    this.amount=this.maxamount
    if (this.numbertoadd != "" && this.numbertoadd != null) {
      if (this.numbertoadd.toString().includes(',')) {
        this.eventvalue = this.functiontoRemoveCommas(this.numbertoadd);
        this.eventvalue = +this.eventvalue;
      }
      else {
        this.eventvalue = +this.numbertoadd
      }
    }
    else {
      this.eventvalue = +this.numbertoadd
    }
    if (b != "" && b != null) {
      if (b.toString().includes(',')) {
        this.formvalue = this.functiontoRemoveCommas(b);
        this.formvalue = +this.formvalue;
      }
      else {
        this.formvalue = +b
      }
    }
    else {
      this.formvalue = +b
    }
    let c = this.eventvalue + this.formvalue;
   this.chathistoryform.controls.amount.setValue(this.commonservice.currencyformat(c))

  }
  public functiontoRemoveCommas(value) {
    let a = value.split(',')
    let b = a.join('')
    let c = b
    return c;
  }
  GetCountdowntime() {
    //////////////Get Auctiondate End time//////////////////////////////// 
    var auctionendtime = new Date(this.auctionenddate).getTime() / 1000;
    console.log("venu timer test", new Date(this.auctionenddate))
    console.log("venu timer venu", new Date(this.currentDatetime))
    let nowdate:any;
    setInterval(() =>{ 
       nowdate =isNullOrUndefined(nowdate) ?new Date(this.currentDatetime):nowdate;
     this.nowtime = nowdate;
     nowdate.setSeconds(nowdate.getSeconds()+1);
    }, 1000);
    //////////////Get current time////////////////////////////////  
    var currenttime = new Date(this.currentDatetime).getTime() / 1000;
    this.countdowntime = auctionendtime - currenttime;
    this.countdowntime = this.countdowntime < 0 ? 0 : this.countdowntime;
    // var popuptime=new Date(this.auctionenddate);
    // popuptime.setSeconds(popuptime.getSeconds()+5); 
    // var showpopuptime=new Date(popuptime).getTime() / 1000;
    // this.countdowntime='-123'; 
    if(this.countdowntime>=0)
    {
    this.prettyConfig = {  
      leftTime: this.countdowntime, format: 'm:ss', prettyText: (text) => { 
        this.LeftTime = convertMinToSec(text);
        this.circlemover = this.LeftTime >0 ? this.LeftTime + " 283" : "283";
        if (this.LeftTime==0) {
          this.countdowntime=0;
          (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = true;
          this.DisableFields(true);
          setTimeout(()=>{
            this.DisplaySuccessPopup();          
          },5000);                  
        }
        else {
          $("#confirmationmodal").modal('hide');
        }
        return text;
      }
    };  
  }
  else
  {
    (<HTMLInputElement>document.getElementById("disablewalkinclients")).disabled = true;
  }
    const convertMinToSec = (str) => {
      const [mm = '0', ss = '0'] = (str || '0:0').split(':');
      const minute = parseInt(mm, 10) || 0;
      const second = parseInt(ss, 10) || 0;
      return (minute * 60) + (second);
    };
    const convertSecToMin = (totalSeconds) => {
      var time;
      const hours = Math.floor(totalSeconds / 3600)
      const minutes = Math.floor((totalSeconds % 3600) / 60)
    
      const seconds = totalSeconds - hours * 3600 - minutes * 60
            var int_length = (''+seconds).length;
            if(int_length==1){
              time=minutes+":0"+seconds
            }
            else{
              time=minutes+":"+seconds
              
            }
      return time
    }

  }
   wait(ms){
    var start = new Date().getTime();
    var end = start;
    while(end < start + ms) {
      end = new Date().getTime();
   }
 }
DisplaySuccessPopup(){
  try{ 
    $("#loading").modal('show');
    setTimeout(()=>{
      setTimeout(()=>{   
        if(!isNullOrUndefined(this.meetingid) && this.LeftTime==0 && this.countdowntime==0){
          this.chatreference.orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
            debugger;
            let chatsdata = [];
            chatsdata = this.commonservice.ConvertfirebaseDataToArray(resp);
            chatsdata = chatsdata.filter(item => item.meetingId == this.meetingid);
            this.maxamount = chatsdata.length > 0 ? Math.max(...chatsdata.map(({ biddamount }) => biddamount)) : 0;
            this.maxticketno = chatsdata.filter(s => s.biddamount == this.maxamount)[0].ticketNo;
            let maxamountarray = chatsdata.filter(item => item.biddamount == this.maxamount && item.meetingId == this.meetingid)
            this.unique = [...new Set(maxamountarray.map(item => item.ticketNo))];
            this.finalChat=chatsdata;
            if (this.unique.length > 1) {     
              if(isNullOrUndefined(this.ChitNo)){
                let modelshow=this.branchdata.pType=='reviewer'?'hide':'show';
               $("#confirmationmodal").modal(modelshow);
              }
             }
             else {
               debugger;
             console.log("single draw", this.unique)
             let successbiddetails:any=[];
             let data2=maxamountarray;//chatsdata.filter(item => item.biddamount == this.maxamount && item.meetingId == this.meetingid)[0];   
               if(this.usertype=='walkin-client'){
                 if(this.branchdata.pType!='reviewer'){
                  successbiddetails=data2;              
                  successbiddetails[0].drawticketno=data2[0].ticketNo;
                  successbiddetails[0].prizedAmount=data2[0].biddamount;;
                  this.mobile=successbiddetails[0].mobileNO;
                  this.successclient=data2[0].subscriberName;
                  this.bidpayble=data2[0].chitvalue-data2[0].biddamount;
                  this.SaveData(successbiddetails[0])
               }
              }
              else{
                setTimeout(()=>{this.WindowCloseStatus=true; window.close()},60000)
              }
           }
          });
        }
      },1000);
      setTimeout(()=>{
        $("#loading").modal('hide');
        this.ShowSpinnerpopup();
        this.ShowSuccesspopup();
      },2000);
    },5000);

}
catch (e) {
  console.log("firebase error on Successpopup",e);
}
}
ShowSpinnerpopup(){
   debugger;
   try{
   
   if(!isNullOrUndefined(this.meetingid) && this.LeftTime==0 && this.countdowntime==0){
  firebase.database().ref('ShowSpinner/').orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => { 
    this.livebiddingservice.setDrawticketnumbers(this.unique)
    this.usertype=this.ChitNo != null?'online-client':'walkin-client';
    let meetingiddata={meetingid:this.meetingid,Auctioneername:this.auctioneer}
    this.livebiddingservice.setMeetingid(meetingiddata);
    let data=resp.exists()?this.commonservice.ConvertfirebaseDataToArray(resp):[];
    data=data.filter(item=>item.meetingId==this.meetingid);
    this.spinnerLoadingstatus=data.length>0?data[0].spinnercalled:false;
    if(this.spinnerLoadingstatus){
      this.key=data[0].key;
      this.tabIndex=0;
      $("#drawwheelmodal").modal('show');
    } 
  });
}
   }
   catch (e) {
    console.log("firebase error on show spinner popup",e);
  }
}
ShowSuccesspopup(){
  try{
  setTimeout(()=>{     
  if(!isNullOrUndefined(this.meetingid) && this.LeftTime==0 && this.countdowntime==0){
  firebase.database().ref('successfullbid/').orderByChild('meetingId').equalTo(this.meetingid).on('value', resp => {
    debugger;
    let data=resp.exists()?this.commonservice.ConvertfirebaseDataToArray(resp):[];
    data=data.filter(item=>item.meetingId==this.meetingid);
    this.drawticketno=data.length>0?data[0].drawticketno:0;
    if(this.drawticketno!=0){
      this.successclient=data[0].subscriberName;
      this.maxamount=data[0].biddamount;
      this.bidpayble=data[0].chitvalue-data[0].biddamount;
      this.drawticketno=data[0].ticketNo;
      this.mobile=data[0].mobileNO;              
      setTimeout(()=>{ 
      $("#drawwheelmodal").modal('hide');
      $("#successpopmodal").modal('show');
    },3000)
    }
  });
}
},1000) 
  }
  catch (e) {
    console.log("firebase error on ShowSuccesspopup",e);
  }
}
testconfirmpopup(){
  this.ticketlength=5;
  this.drawticketnos=[2,5,6,4,8]
  $("#confirmationmodal").modal('show');
}
testwheelpopup(){
  this.drawticketnos=[2,5,6,4,8]
this.livebiddingservice.setDrawticketnumbers(this.drawticketnos)
this.usertype='walkin-client';
let meetingiddata={meetingid:"KKA021121120_FJJT26F_28",Auctioneername:"Mrs. PADMAJARANI THURLAPATI"}
this.livebiddingservice.setMeetingid(meetingiddata);
 this.tabIndex=0;
      $("#drawwheelmodal").modal('show');
  
}
testsuccesspopup(){
  this.drawticketno=5;
  this.successclient="Mrs. PADMAJARANI THURLAPATI";
  this.maxamount=20000;
  this.bidpayble=1250000;
      $("#successpopmodal").modal('show');
  
}

confirmBox() {
  const confirmBox = new ConfirmBoxInitializer();
  confirmBox.setTitle('Your Internet Connection is Unstable');
  confirmBox.setMessage('Please check your network stability and then reload this page.');
  // Set button labels, the first argument for the confirmation button, and the second one for the decline button.
  confirmBox.setButtonLabels('Reload Page Now');
  
  confirmBox.setConfig({
      DisableIcon: true, // optional
      AllowHTMLMessage: false, // optional
      ButtonPosition: 'center', // optional
      // Evoke the confirmation box with predefined types.
      LayoutType: DialogLayoutDisplay.INFO // SUCCESS | INFO | NONE | DANGER | WARNING
  });
  
  // Simply evoke the popup and listen which button is clicked.
  const subscription = confirmBox.openConfirmBox$().subscribe(resp => {
      // IConfirmBoxPublicResponse
      if(resp.Success==true)
      {
            location.reload();
      }
     
      // console.log('ConfirmBox button response: ', resp);
      subscription.unsubscribe();
  });
}

}
