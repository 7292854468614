import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { CommonService } from 'src/app/Services/common.service';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-hall-allotment',
  templateUrl: './hall-allotment.component.html',
  styles: []
})
export class HallAllotmentComponent implements OnInit {
  hallname: any;
  employeename: any;
  buttondisabled: boolean;
  branchdata:any={}
  halldata;any=[]
  constructor(private router:Router,private formBuilder:FormBuilder,private hallallotmentservice:HallAllotmentService,private commonservice:CommonService) { }
  hallallotmentform:FormGroup
  EmployeeList:any=[]
  Halllist:any=[]
  HallAllotmentvalidations:any;
  
  ngOnInit()
   {
     this.buttondisabled=false
    this.HallAllotmentvalidations={}
    this.hallallotmentform = this.formBuilder.group({
      pConductedby: [''],
      pHallName:[null,Validators.required],
      pEmployeeName:[null,[Validators.required]],
      pBranchid:[''],
      pBranchName:[''],
      pMobileNo:['']

    });
    debugger;
    this.BlurEventAllControll(this.hallallotmentform)
    this.GetBranchDetails()
    this.GetEmployees();
    this.GetHallList();
    this.GetHallAllotmentList()
  }
   GetBranchDetails()
   {
    this.branchdata=this.hallallotmentservice.GetBranchDetails()
    console.log("data",this.branchdata)
   }
  GetEmployees()
  {
    this.hallallotmentservice.getEmployeeList(this.branchdata.pBranchName).subscribe(json=>{
      console.log("employeelist",json)
      this.EmployeeList=json
    })
  }
  GetHallList()
  {
    this.hallallotmentservice.getHallLists(this.branchdata.pBranchName).subscribe(json=>{
      console.log("Halllist",json)
      this.Halllist=json
    })
  }
  GetHallAllotmentList()
  {
    this.hallallotmentservice.GetHallAllotmentList(this.branchdata.pBranchName).subscribe(json=>{
      this.halldata=json;
      console.log("hall data",this.halldata)
    })
  }
  Employeechange(event)
  {
    debugger
    if(!isNullOrUndefined(event)){
    this.buttondisabled=false
      this.employeename=event.pEmployeeName
      this.hallallotmentform.controls.pConductedby.setValue(event.pEmployeeName)
      this.hallallotmentform.controls.pBranchName.setValue(event.pBranchName);
      this.hallallotmentform.controls.pMobileNo.setValue(event.pMobileNo)
  }
}
  HallChange(event)
  {
    debugger;
    if(!isNullOrUndefined(event)){
    this.buttondisabled=false
    this.hallname=event.pHallName
  }
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {

      this.showErrorMessage(e);
      return false;
    }
    return isValid;
  }
  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {

    try {
      let formcontrol;

      formcontrol = formGroup.get(key);

      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {

          //if (key != 'InsuranceMemberNomineeDetailsList')
          //  this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.HallAllotmentvalidations[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;

            for (const errorkey in formcontrol.errors) {
              if (errorkey) {

                let lablename;

                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this.commonservice.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.HallAllotmentvalidations[key] += errormessage + ' ';
                isValid = false;
              }
            }

          }
        }
      }

    }
    catch (e) {
      this.commonservice.showErrorMessage(key);
      return false;
    }
    return isValid;
  }
  showErrorMessage(errormsg: string) {
    this.commonservice.showErrorMessage(errormsg);
  }
  BlurEventAllControll(fromgroup: FormGroup) {

    try {

      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })

    }
    catch (e) {
      this.commonservice.showErrorMessage(e);
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {

    try {
      let formcontrol;

      formcontrol = fromgroup.get(key);


      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {

          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }

    }
    catch (e) {
      this.commonservice.showErrorMessage(e);
      return false;
    }



  }
  openAuctionHistory()
  {
    this.router.navigateByUrl('/auctionhistory')
  }
  openprizedsubsribers()
  {
    this.router.navigateByUrl('/auctiondata')
  }
  submit()
  {
    this.buttondisabled=true
    let isValid:boolean=true
   // this.router.navigate(['/Authentication'])
    if(this.checkValidations(this.hallallotmentform,isValid))
    {
       debugger
      this.hallallotmentservice.checkHallAllotmentExist(this.employeename,this.hallname,this.branchdata.pBranchName).subscribe(json=>{
        console.log("json",json)
         let response=json
        if(!response)
        {
          this.hallallotmentform.controls.pBranchid.setValue(this.branchdata.pBranchId)
          console.log(this.hallallotmentform.value)
          let data=JSON.stringify(this.hallallotmentform.value)
         
          this.hallallotmentservice.SaveAllotmentData(data).subscribe(json=>{
            if(json)
            {
              this.buttondisabled=false;
              this.hallallotmentform.controls.pHallName.setValue(null)
              this.hallallotmentform.controls.pEmployeeName.setValue(null)
              this.HallAllotmentvalidations={}
              this.commonservice.showInfoMessage("Saved Successfully")
            
              this.GetHallAllotmentList();
               //this.router.navigate(['/Authentication'])
            }
            
          })
          
        }
        else{
          this.buttondisabled=false
          this.commonservice.showWarningMessage("Already Existed")
          this.hallallotmentform.controls.pHallName.setValue(null)
          this.hallallotmentform.controls.pEmployeeName.setValue(null)
          this.HallAllotmentvalidations={}
        }
      })
      
     
    }
    this.buttondisabled=false;
   
  }
}
