import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class HallAllotmentService {
  branchdata: any={}
  data:any=[]
  constructor(private commonService:CommonService) { }

  getEmployeeList(Branchname): Observable<any> {
    try {
      const params = new HttpParams().set('Branchname', Branchname);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/GetEmployees', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
  getHallLists(Branchname): Observable<any>{
    try {
      const params = new HttpParams().set('Branchname', Branchname);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/HallsList', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
  checkHallAllotmentExist(employeename,hallname,branchname)
  {
    try {
      const params = new HttpParams().set("employeename", employeename).set('hallname',hallname).set('branchname',branchname);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/checkHallAllotmentExist', params, 'YES');
    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
  SaveAllotmentData(data) {
    return this.commonService.callPostAPI('/Auctions/HallAllotment/SaveHallAllotment', data);
  }
  // SetBranchDetails(branchdata)
  // {
   
  //    this.branchdata=branchdata
  //    console.log("branchdata",this.branchdata)
  // }
  GetBranchDetails()
  {
   
     this.branchdata=JSON.parse(sessionStorage.getItem("currentUser"));
     console.log("branchdata",this.branchdata)
     return this.branchdata;
  }
  
  // GetBranchDetails()
  // {
  //   return this.branchdata
  // }
  AuthenticateOTP(Authenticationid,OTPCode)
  {
    try {
      const params = new HttpParams().set("Authenticationid", Authenticationid).set('OTPCode',OTPCode);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/AuthenticateOTP', params, 'YES');
    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
  GetHallAllotmentList(Branchname)
  {
    try {
      const params = new HttpParams().set('Branchname', Branchname);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/GetHallAllotmentList', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
  SetGroupData(data){
    this.data=data
  }
  GetGroupData()
  {
    return this.data
  }
  TicketDetails(GroupCode,Branchname)
  {
    try {
      const params = new HttpParams().set('GroupCode', GroupCode).set('Branchname',Branchname);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/TicketDetails', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
  getGroupDetails(Hallname,branchname): Observable<any>{
    try {
      const params = new HttpParams().set('Hallname', Hallname).set('branchname', branchname);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/GroupDetails', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
   GetMaxbidoffer(GroupCode,Branchname,auctionmonth):Observable<any>{
    try {
      const params = new HttpParams().set('GroupCode', GroupCode).set('Branchname', Branchname).set('auctionmonth',auctionmonth);
      return this.commonService.callGetAPI('/Auctions/HallAllotment/GetMaxbidoffer', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
   }
 SaveMaxbidoffer(data)
 {
  return this.commonService.callPostAPI('/Auctions/HallAllotment/SaveMaxbidoffer', data);
 }
 GetReAuctionBranches()
 {
  try {
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetReAuctionBranches', '', 'NO');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
 }
 GetReAuctionGroups(branchname)
 {
  try {
    const params = new HttpParams().set('branchname', branchname);
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetReAuctionGroups', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
 }
 GetRescheduleGroups(branchname)
 {
  try {
    const params = new HttpParams().set('branchname', branchname);
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetReSheduleGroups', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
 }
 GetEmployeeDetails(BranchName)
 {
  try {
    const params = new HttpParams().set('BranchName', BranchName);
    return this.commonService.callGetAPI('/Employee/GetEmployeeDetails', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
 }
checkMobileNumberExist(branchname,mobileno)
 {
  try {
    const params = new HttpParams().set('branchname', branchname).set('mobileno',mobileno);
    return this.commonService.callGetAPI('/Employee/checkMobileNumberExist', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
 }
 SaveReauction(data)
 {
  return this.commonService.callPostAPI('/Auctions/Livebidding/saveReauctionData', data);
 }
 SaveReschedule(data)
 {
  return this.commonService.callPostAPI('/Auctions/Livebidding/saveReSheduleData', data);
 }
 SaveEmployee(data)
 {
  return this.commonService.callPostAPI('/Employee/SaveEmployee', data);
 }
 GetBranchNames()
 {
  try {
    return this.commonService.callGetAPI('/Employee/GetBranchNames', '', 'NO');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
 }

}
