import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CommonService } from '../common.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuctionHistoryService {

  constructor(private _CommonService: CommonService) { }

  GetBranchDetails(fromDate,BranchName): Observable<any> {
    const params = new HttpParams().set('fromDate', fromDate).set('BranchName',BranchName);
    return this._CommonService.callGetAPI('/Auctions/Reports/GetBranchnames', params, 'YES');
  }

  GetGroupCodes(Branch,fromDate): Observable<any> {
    const params = new HttpParams().set('Branch',Branch).set('fromDate', fromDate);
    return this._CommonService.callGetAPI('/Auctions/Reports/GetGroupcodes', params, 'YES');
  }

  GetAuctionDetails(Branch,Groupcode,fromDate): Observable<any> {
    const params = new HttpParams().set('Branch',Branch).set('Groupcode',Groupcode).set('fromDate', fromDate);
    return this._CommonService.callGetAPI('/Auctions/Reports/GetAuctionDetails', params, 'YES');
  }
  GetPrizedSubscribersData(Branch,Groupcode,fromDate):Observable<any>
  {
    const params = new HttpParams().set('Branch',Branch).set('Groupcode',Groupcode).set('fromDate', fromDate);
    return this._CommonService.callGetAPI('/Auctions/Reports/GetAuctionData', params, 'YES');
  }
  GetAuthorizedGroupcodes(Branch)
  {
    const params = new HttpParams().set('Branch',Branch)
    return this._CommonService.callGetAPI('/Auctions/Reports/GetAuthorizedGroupcodes', params, 'YES');
  }
GetChitAuthorizedData(Branch,groupcode)
{
  const params = new HttpParams().set('Branch',Branch).set('groupcode',groupcode);
    return this._CommonService.callGetAPI('/Auctions/Reports/GetChitAuthorizedData', params, 'YES');
}
GetChitUnAuthorizedData(Branch,groupcode)
{
  const params = new HttpParams().set('Branch',Branch).set('groupcode',groupcode);
  return this._CommonService.callGetAPI('/Auctions/Reports/GetChitUnAuthorizedData', params, 'YES');
}

}
