import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { CommonService } from 'src/app/Services/common.service';
import { MockdataService } from 'src/app/Services/mockdata.service';
import { Router } from '@angular/router';
import { Console } from 'console';


@Component({
  selector: 'app-mock-data',
  templateUrl: './mock-data.component.html',
  styles: []
})
export class MockDataComponent implements OnInit {

  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  branch: any;
  group: any;
  time: any;
  mobileno: any;
  buttonname: string;
  disablesavebutton: boolean;
  currenttime:any;
  constructor(private datepipe:DatePipe,private fb:FormBuilder,private mockdataservice:MockdataService,
    private hallallotmentservice:HallAllotmentService,private router: Router,private _CommonService:CommonService)
     { 
      this.dpConfig.dateInputFormat = 'DD-MM-YYYY'
      this.dpConfig.maxDate = new Date();
      this.dpConfig.minDate = new Date();
      this.dpConfig.showWeekNumbers = false;

     
     }
     branchdetails:any=[]
     groupcodedetails:any=[]
     auctioneerdetails:any=[]
  Mockdata:FormGroup;
  Mockdataerrors:any
  Mockdatagrid:any=[]
  Mockgrid:any=[]
  min: Date = new Date(); 
  
  ngOnInit() 
  {
    this.buttonname='Update'
    this.GetformData();
    this.GetMockDataBranches();
    this.BlurEventAllControll(this.Mockdata)
  }
  GetformData()
  {
    
    this.Mockdataerrors = {}
    this.Mockdata = this.fb.group({
    
      branch : [null, Validators.required],
      groupcode:[null,Validators.required],
      auctioneer :[null,Validators.required],
      auctioneerMobile :[null],
      auctiondate :[new Date()],
      value:[''],
      auctiontime: [''],
      mockDataTicketsList: []
    });
  }
  Mockdataticketlist() {
    return this.fb.group
      ({
        branch: [''],
        due:[''],
        groupcode: [''],
     
        ticketNo: [''],
        subscribername: [''],
        mobileNo: [''],
       
      })
  }
  GetMockDataBranches()
  {
    this.mockdataservice.GetMockDataBranches().subscribe(json=>{
      debugger;
      this.branchdetails=json
      let time=this.branchdetails[0].currentDatetime;
      //  let timeee= new Date('2021/03/15 10:58:21');
      this.Mockdata.controls.value.setValue(new Date(time))
      this.Mockdata.controls.auctiontime.setValue(this.datepipe.transform(time,'HH:mm'))
    this.min=new Date(time);
    })
  }

  Branchchange(event)
  {
    debugger
    if(event!=undefined)
    {
      this.Mockdatagrid=[]
      this.Mockdata.controls.groupcode.setValue(null);
      this.Mockdata.controls.auctioneer.setValue(null);
     
      this.Mockdataerrors={}
      this.branch=event.branch
      this.GetGroupCodes();
      this.GetMockDataAuctioneers()
    }
    else{
      this.Mockdata.controls.groupcode.setValue(null);
      this.Mockdata.controls.auctioneer.setValue(null);
      this.groupcodedetails=[];
      this.Mockdatagrid=[]

      this.auctioneerdetails=[]
      this.Mockdataerrors={}
    }
  }
  GetGroupCodes()
  {
    
    if(this.branch!=undefined)
    {
      this.mockdataservice.GetMockDataGroups(this.branch).subscribe(json=>{
        this.groupcodedetails=json
        console.log("groupdetails",json)
        if(this.groupcodedetails.length>0){
          let time=this.groupcodedetails[0].currentDatetime;
          let timeee= new Date(time);
          this.Mockdata.controls.value.setValue(new Date(time))
          this.Mockdata.controls.auctiontime.setValue(this.datepipe.transform(time,'HH:mm'))
          this.min=new Date(time);  
        }
        
    })
    }
  }
  GetMockDataAuctioneers()
  {
    if(this.branch!=undefined)
    {
      this.mockdataservice.GetMockDataAuctioneers(this.branch).subscribe(json=>{
        this.auctioneerdetails=json
        
      })
    }
  }

  groupcodechange(event)
  {    
    if(event!=undefined)
    {
      this.Mockdatagrid=[]
      this.group=event.groupcode
    }
  }
  GetMockDataTickets()
  {
    let auctioneermobileno = <FormGroup>this.Mockdata['controls']['auctioneerMobile'];
    let time = <FormGroup>this.Mockdata['controls']['value'];
    auctioneermobileno.clearValidators()
    auctioneermobileno.updateValueAndValidity()
    time.clearValidators()
    time.updateValueAndValidity()
    let isValid: boolean = true;

    if(this.checkValidations(this.Mockdata,isValid))

    {
      this.mockdataservice.GetMockDataTickets(this.branch,this.group).subscribe(json=>{
        console.log("data",json);
        this.Mockdatagrid=json
      })
    }
     
  }
  onChange(test)
  {
    debugger;
   this.mockdataservice.GetCurrenttime().subscribe(json=>{
     console.log(json);
     (json);
     debugger;
     let time=json["currentdatetime"];
this.currenttime=new Date(time);
let selectedtime=new Date(test);
if(selectedtime<=this.currenttime){
this._CommonService.showWarningMessage("Selected time should not be less than current time")
this.time=(this.datepipe.transform(this.currenttime,'HH:mm'))
this.Mockdata.controls.auctiontime.setValue(this.time)

this.min=new Date(this.currenttime)
}
else{
  this.time=(this.datepipe.transform(test,'HH:mm'))
  this.Mockdata.controls.auctiontime.setValue(this.time)
}
     
   })
    
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {
      return false;
    }
    return isValid;
  }

  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {
    try {

      let formcontrol;
      formcontrol = formGroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.Mockdataerrors[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;
            for (const errorkey in formcontrol.errors) {
              if (errorkey) {
                let lablename;
                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this._CommonService.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.Mockdataerrors[key] += errormessage + ' ';
                isValid = false;
              }
            }
          }
        }
      }
    }
    catch (e) {
      return false;
    }
    return isValid;
  }
  BlurEventAllControll(fromgroup: FormGroup) {
    try {
      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })
    }
    catch (e) {
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {
    try {
      let formcontrol;
      formcontrol = fromgroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }
    }
    catch (e) {
      return false;
    }
  }
  changemobileno(event,dataItem)
  {
    debugger;
   
    
    this.mobileno=event.target.value;
    dataItem.mobileNo=this.mobileno;
    this.Mockgrid.push(dataItem)
    console.log(dataItem);


  }
  Auctioneer(event)
  {
    if(event!=undefined)
    {
      this.Mockdatagrid=[]
      
    }
  }
  AuctioneerMobileNo(event)
  {
    debugger
    let auctioneermobileno = <FormGroup>this.Mockdata['controls']['auctioneerMobile'];
    auctioneermobileno.clearValidators();
    
    auctioneermobileno.updateValueAndValidity()
  }
Update()
{
  debugger
  let auctioneermobileno = <FormGroup>this.Mockdata['controls']['auctioneerMobile'];
  let time = <FormGroup>this.Mockdata['controls']['value'];
  auctioneermobileno.setValidators(Validators.required)
  auctioneermobileno.updateValueAndValidity()
  time.setValidators(Validators.required);
  time.updateValueAndValidity()
  let isValid:boolean=true
  if(this.checkValidations(this.Mockdata,isValid))
  {
      debugger
    
      if(this.Mockgrid.length>0)
      {
        this.Mockdata['controls']['mockDataTicketsList'].setValue(this.Mockgrid);

        console.log("form value",this.Mockdata.value);
        let data=JSON.stringify(this.Mockdata.value);
        this.disablesavebutton = true;
        this.buttonname = 'Processing';
        console.log("ttttt",time.value)
      if(time.value>this.currenttime){
        this.mockdataservice.UpdateSubscriberMobileNumbers(data).subscribe(json=>{
          console.log("after saving",json);
         
          if(json)
          {
            this.disablesavebutton = false;
            this.buttonname='Update'
            this._CommonService.showInfoMessage("Data Updated Successfully");
           this.GetformData();
           this.Mockdatagrid=[];
          //  this.router.navigate(['/Authentication'])
           
           
          }
        })
      }
       else{
        this.disablesavebutton = false;
        this.buttonname='Update';
        this._CommonService.showWarningMessage("Selected time should not be less than current time")
       }
      }
      else{
        this._CommonService.showWarningMessage("Add atleast one mobile number")
      }
       
  }
}
}
