import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { UsersService } from 'src/app/Services/Settings/Users/Users.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDetailsService } from '../../../../Services/Common/company-details.service';
import firebase from 'firebase';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { IpaddressService } from 'src/app/Services/ipaddress.service';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styles: []
})
export class UserLoginComponent implements OnInit {

    LoginForm: FormGroup;
    submitted = false;
    ref = firebase.database().ref('users/');
    ipAddress: any;
    constructor(private _commonService: CommonService, private router: Router, private _UsersService: UsersService, private _route: ActivatedRoute, private toastr: ToastrService, 
        private _CompanyDetailsService: CompanyDetailsService,private http:HttpClient,
        private hallallotmentservice:HallAllotmentService,private ip:IpaddressService) {

    }
  
    ngOnInit() {
  
debugger
        this.LoginForm = new FormGroup({
            pUserName: new FormControl('', Validators.required),
             pPassword: new FormControl('', Validators.required)
        });
        // this._commonService.getIPAddress().subscribe((res: any) => {
        //     debugger
        //     let data3 = res;
        //     sessionStorage.setItem('IPAddress', JSON.stringify(data3));
        //     this._commonService._setIPAddress();
        //   }, (error) => {
        //     this._commonService.showErrorMessage(error);
        //   });
       
    }

    loginclick() {

        debugger
        this.submitted = true;
        if (this.LoginForm.valid)
         {
            
            let Logindata = this.LoginForm.value;
            let jsondata = JSON.stringify(this.LoginForm.value);
            // this.http.get('http://api.ipify.org/?format=json')
            // .subscribe((res: any) => 
            // {
            //     debugger
            //   this._commonService.IpAddress = res.ip;
            // sessionStorage.setItem("ipaddress", res.ip);
            //   console.log("Ip-In-LOGIN",res.ip)
            // });
            // this.ref.orderByChild('nickname').equalTo(this.LoginForm.controls.pUserName.value).once('value', snapshot => {
            //     debugger;
            //     if (snapshot.exists()) {
            //       localStorage.setItem('nickname', this.LoginForm.controls.pUserName.value);
            //       // this.router.navigate(['/roomlist']);
            //       localStorage.setItem('nickname', this.LoginForm.controls.pUserName.value);
            //       this.router.navigate(['/Livebidding']);
                  
            //     } 
            //     else{
            //         this.toastr.error("Invalid Credentials", "Error")
            //     }
            //   });
            //venu/////////////

             
            //venu end/////////////////////
            this._UsersService._loginUser(jsondata).subscribe(data => {
                debugger

                sessionStorage.setItem('currentUser', JSON.stringify(data));
               let type=data["pType"];
                //this.hallallotmentservice.SetBranchDetails(data)
                //this.router.navigate(['/Hallallotment'])

                if(type=="admin")
                {
                    this.router.navigate(['/auctiondata'])
                }
              else if(type!="reviewer"){
                    this.router.navigate(['/Hallallotment'])
                    
                }
                else{
                    this.router.navigate(['/Reviewer'])
                }
               
        


            }, error => {
                
                    ``
            });
        }

    }
 
    getIP()
    {
      this.ip.getIPAddress().subscribe((res:any)=>{
        this.ipAddress=res.ip;
      });
    this.ip.getIPAddress().subscribe((res:any)=>{
        this.ipAddress=res.ip
    })
    }
}
