import { Component, OnInit } from '@angular/core';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LivebiddingService } from 'src/app/Services/livebidding.service';
import firebase from 'firebase';
import { CommonService } from 'src/app/Services/common.service';
import { ConfirmBoxInitializer, DialogLayoutDisplay } from '@costlydeveloper/ngx-awesome-popup';
import { Alert } from 'selenium-webdriver';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-group-selection',
  templateUrl: './group-selection.component.html',
  styles: []
})
export class GroupSelectionComponent implements OnInit {
  Groupcode: any;
  hallname: any;
  branchdata: any;
  auctionerdetails: any= [];
  meetingid: any;
  branchid: string;
  branchname: string;
  constructor(private hallallotmentservice: HallAllotmentService,
    private router: Router, private activatedroute: ActivatedRoute, private livebiddingService: LivebiddingService, private commonservice: CommonService) { }
  groupdata: any = []

  ngOnInit() {
    debugger;
    this.branchdata = this.hallallotmentservice.GetBranchDetails()
    let routeParams = this.activatedroute.queryParams.subscribe(params => {
      debugger;
      let routeparmeter = atob(params['id']);
      let splitData = routeparmeter.split(",");
      this.hallname=splitData[0];
      this.branchid=splitData[1]
      this.branchname=splitData[2];
      setInterval(()=>{this.GetGroupDetails(this.hallname,this.branchname)},1000)
      
    });
  }
  GetGroupDetails(hallname,branchname) {
    this.hallallotmentservice.getGroupDetails(hallname,branchname).subscribe(json => {
      this.groupdata = json;
      console.log("groupdata",this.groupdata)
    });
  }
  Initiate(dataItem) {
    debugger
    this.Groupcode = btoa(dataItem.pGroupCode)
  
    this.livebiddingService.InitiateAuction(dataItem.pGroupCode, dataItem.pAuctionHall,this.branchid).subscribe(json => {
      this.meetingid = json["meetingid"];
      this.router.navigate(['/TicketSelection'], { queryParams: { id: this.Groupcode } });
    })
    debugger;
    
    //this.router.navigate(['/TicketSelection/' + this.Groupcode])


  }


  confirmBox() {
   
  }
 
  Rejoin(dataItem)
  {

    firebase.database().ref('auctioneer/').orderByChild('groupcode').equalTo(dataItem.pGroupCode).once('value')
    .then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        console.log(childSnapshot.val());
        childSnapshot.ref.update({'status':'N'});
      });
    });
    setTimeout(()=>{  
      this.Groupcode =dataItem.pGroupCode;
      let array=[]
      this.livebiddingService.SetTicketDetails(this.Groupcode,array,"Rejoin");
      window.open("/#/Livebidding", "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no,location=no,width=1800,height=1200");
    },1000)         
  }
  
}


