import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, ControlContainer } from '@angular/forms';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { CommonService } from 'src/app/Services/common.service';

@Component({
  selector: 'app-re-auction',
  templateUrl: './re-auction.component.html',
  styles: []
})
export class ReAuctionComponent implements OnInit {
  branch: any;
  groupcodedetails:any=[]
  time: string;

  constructor(private datepipe:DatePipe,private fb:FormBuilder,
    private hallallotmentservice:HallAllotmentService,private _CommonService:CommonService) { }
  Reauction:FormGroup
  Reacutionerrors:any;
  branchdetails:any=[]
  min: Date = new Date(); 
  ngOnInit() 
  {
    this.GetformData();
    this.GetReAuctionBranches();
    this.BlurEventAllControll(this.Reauction)
  }
 // public value: Date = new Date();
  public GetformData() {
    this.Reacutionerrors = {}
    
    this.Reauction = this.fb.group({
    
      branchName: [null, Validators.required],
      groupcode:[null,Validators.required],
      value:[new Date()],
      auctiontime: [null],
     
    });
   
  }

  GetReAuctionBranches()
  {
     this.hallallotmentservice.GetReAuctionBranches().subscribe(json=>
      {
        debugger;
        console.log("bramchdetails",json)
        this.branchdetails=json;
        let time=this.branchdetails[0].currentdatetime;
        this.Reauction.controls.value.setValue(new Date(time));
        this.time=(this.datepipe.transform(time,'HH:mm'));
        this.Reauction.controls.auctiontime.setValue(this.time);
        this.min=new Date(time);

     })
  }
  onChange(test)
  {
    debugger;
     this.time=(this.datepipe.transform(test,'HH:mm'))
     this.Reauction.controls.auctiontime.setValue(this.time)
  }
  Branchchange(event)
  {
    debugger
    if(event!=undefined)
    {
      this.Reauction.controls.groupcode.setValue("");
      this.Reacutionerrors={}
      this.branch=event.branchName
      this.GetGroupCodes()
    }
    else{
      this.Reauction.controls.groupcode.setValue("");
      this.Reacutionerrors={}
    }
  }
  GetGroupCodes()
  {
    debugger
    if(this.branch!=undefined)
    {
      this.hallallotmentservice.GetReAuctionGroups(this.branch).subscribe(json=>{
        debugger;
        this.groupcodedetails=json
        if(this.groupcodedetails.length>0){
          let time=this.groupcodedetails[0].currentdatetime;
          this.Reauction.controls.value.setValue(new Date(time));
          this.time=(this.datepipe.transform(time,'HH:mm'));
          this.Reauction.controls.auctiontime.setValue(this.time);

          this.min=new Date(time);
          console.log("groupdetails",json)
        }
      
      })
    }
      
    
  
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {
      return false;
    }
    return isValid;
  }

  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {
    try {

      let formcontrol;
      formcontrol = formGroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.Reacutionerrors[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;
            for (const errorkey in formcontrol.errors) {
              if (errorkey) {
                let lablename;
                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this._CommonService.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.Reacutionerrors[key] += errormessage + ' ';
                isValid = false;
              }
            }
          }
        }
      }
    }
    catch (e) {
      return false;
    }
    return isValid;
  }
  BlurEventAllControll(fromgroup: FormGroup) {
    try {
      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })
    }
    catch (e) {
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {
    try {
      let formcontrol;
      formcontrol = fromgroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }
    }
    catch (e) {
      return false;
    }
  }
  Save()
  {
    debugger
    let isValid: boolean = true;
    let data=JSON.stringify(this.Reauction.value)
    console.log(data)
    if(this.time==undefined)
      {
        this.time=(this.datepipe.transform(new Date(),'HH:mm'))
        this.Reauction.controls.auctiontime.setValue(this.time)
      }
    if(this.checkValidations(this.Reauction,isValid))
    {
      let data=JSON.stringify(this.Reauction.value)
      console.log(data)
      
     
        this.hallallotmentservice.SaveReauction(data).subscribe(json=>{
          console.log(json);
          if(json)
          {
            this._CommonService.showInfoMessage("Saved Successfully");
            this.Reauction.controls.branchName.setValue(null)
            this.Reauction.controls.groupcode.setValue(null);
            this.Reacutionerrors={}
    
          }
        
       
          })
      
     
    }
  }
}
