import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { DefaultProfileImageServiceService } from 'src/app/Services/Common/default-profile-image-service.service';
import { CommonService } from 'src/app/Services/common.service';
import { Router, NavigationStart } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UsersService } from 'src/app/Services/Settings/Users/Users.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from '../../Services/Settings/Users/_helpers/must-match.validator';
import { Subscription } from 'rxjs';
import { NgxWheelComponent, TextAlignment, TextOrientation } from 'ngx-wheel'
import { LivebiddingService } from 'src/app/Services/livebidding.service';
import { isNullOrUndefined } from 'util';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { MenuService } from 'src/app/Services/menu.service';
declare let $: any
export let browserRefresh = false;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.html',
  styles: []
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild(NgxWheelComponent, { static: false }) wheel;
  seed = [...Array(12).keys()]
  drawitems:any[];
  idToLandOn: any;
  items: any[];
  textOrientation: TextOrientation = TextOrientation.HORIZONTAL
  textAlignment: TextAlignment = TextAlignment.OUTER
  public TitleData: string = "";
  public loading = false;
  FormTitleData: any
  headerTitle: any
  modeldatalinks: any
  preActiveLink: any
  UsersForms: any;
  UserName: any;
  ChangePassWordForm: FormGroup;
  submitted = false;
  MenuName: any;
  SubMenuName: any;
  UrlName: any;
  showreauction:boolean
  LoginUser: any;
  auctioneer:any;
  showhallallotment:boolean;
  showauthorization:boolean
  showreveiwer:boolean;
  Imagepath: any;
  userFilter: any = { pFunctionName: '' };
  nickname:any;
  branchdata: any;
  mainmenu: any=[];
  submenu:any=[]
  constructor(private _DefaultProfileImageService: DefaultProfileImageServiceService,
    private _CommonService: CommonService, private formBuilder: FormBuilder, private router: Router, private _CookieService: CookieService, private _userService: UsersService,
     private toastr: ToastrService,private livebiddingservice:LivebiddingService,private hallallotmentservice:HallAllotmentService,
     private menuservice:MenuService) {
    

    browserRefresh = !this.router.navigated;
    debugger;
    if (browserRefresh == true) {

     // this.router.navigate(['/Dashboard']);
      this.SubMenuName = 'Home';
     // this.UrlName = "Dashboard"
    }


  }
 ngOnInit(){
   
  this.nickname=localStorage.getItem('nickname') ;
 this.showreveiwer=false
 this.showhallallotment=false
 this.showreauction=false
  this.idToLandOn = this.seed[Math.floor(Math.random() * this.seed.length)];
    const colors = ['#FF0000', '#000000']
  
    this.items = this.seed.map((value) => ({
      fillStyle: colors[value % 2],
      text: `Prize ${value}`,
      id: value,
      textFillStyle: 'white',
      textFontSize: '16'
    }))
    
    let userdetails=this._userService._getUser();
if(isNullOrUndefined(userdetails.auctioneer)){
  this.auctioneer="Admin";
}
else{
  this.auctioneer=userdetails.auctioneer;

}
    
    console.log("dataitems",this.items)

    this.branchdata=this.hallallotmentservice.GetBranchDetails();
      console.log("branchdata",this.branchdata)
      if(this.branchdata.pType=="auctioneer")
      {
        this.showhallallotment=false
        this.showreveiwer=false
      }
      else if(this.branchdata.pType=="reviewer")
      {
        this.showreveiwer=true
      }
      else if(this.branchdata.pType=="admin"){
        debugger
        this.showreauction=true;
        this.showauthorization=true;
        // this.menuservice.GetAuctionUserModule(this.branchdata.pType).subscribe(json=>{
        //   if(json)
        //   {
            
        //     this.mainmenu=json['moduleDTOList'];
            
            for(let i=0;i<this.mainmenu.length;i++)
            {
              this.submenu=this.mainmenu[i].lstAucitonFunctionsDTO
            }
            console.log("menu", this.mainmenu);
          }
        // })
       
       
      //}
      else{
        this.showhallallotment=true;
        this.showauthorization=true
        
      }
      debugger;
      this.menuservice.GetAuctionUserModule(this.branchdata.pType).subscribe(json=>{
        if(json)
        {
          
          this.mainmenu=json['moduleDTOList'];
          
          for(let i=0;i<this.mainmenu.length;i++)
          {
            this.submenu=this.mainmenu[i].lstAucitonFunctionsDTO
          }
          console.log("menu", this.submenu);
        }
      })
 }

 reset() {
  this.wheel.reset()
}
before() {
  
}

async spin(prize) {
  
  this.idToLandOn = prize
  await new Promise(resolve => setTimeout(resolve, 0));
  this.wheel.spin()
}

after() {
  
}
  Urlclick(formname, submenu, menuname) {

    this.MenuName = menuname;
    this.SubMenuName = submenu
    this.UrlName = formname
  }
  Signout() {
    this._userService._logout();
  }
  ngOnDestroy() {
  }

 


  


 
}
