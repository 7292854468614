import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MockdataService {

  constructor(private commonService:CommonService) { }


  GetMockDataBranches()
{
  try {
    return this.commonService.callGetAPI('/Auctions/MockData/GetMockDataBranches', '', 'NO');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
GetMockDataGroups(Branch)
{
  try {
    const params = new HttpParams().set('Branch', Branch);
    return this.commonService.callGetAPI('/Auctions/MockData/GetMockDataGroups', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
GetMockDataAuctioneers(Branch)
{
  try {
    const params = new HttpParams().set('Branch', Branch);
    return this.commonService.callGetAPI('/Auctions/MockData/GetMockDataAuctioneers', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}

GetMockDataTickets(Branch,Groupcode)
{
  try {
    const params = new HttpParams().set('Branch', Branch).set('Groupcode',Groupcode);
    return this.commonService.callGetAPI('/Auctions/MockData/GetMockDataTickets', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
GetCurrenttime()
{
  debugger;
  try {
    
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetCurentTime', '', 'NO');
    debugger;

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
UpdateSubscriberMobileNumbers(data)
{
  return this.commonService.callPostAPI('/Auctions/Livebidding/UpdateSubscriberMobileNumbers', data);
}
}

