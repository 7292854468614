import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder, AbstractControl, Validators, FormControl, FormArray } from '@angular/forms';


import { Observable } from 'rxjs/Rx'
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../environments/environment';
import appsettings from '../../assets/appsettings.json';
import { mergeMap } from 'rxjs/operators';
import { Subject } from 'rxjs'
import { DatePipe } from '@angular/common';
import { debug } from 'util';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
@Injectable({
    providedIn: 'root'
})
export class CommonService {
    //apiURL = appsettings[0].ApiHostUrl;
    FiTab1Details: any
    //BankData: any;
    private ActiveTabName = new Subject<any>()
    private FiTab1Data = new Subject<any>();
    private BankData = new Subject<any>();
    private BankUpdate = new Subject<any>();
    private KYCData = new Subject<any>();
    private KYCUpdate = new Subject<any>();
    private TDSData = new Subject<any>();
    private TDSUpdate = new Subject<any>();
    private ContactData = new Subject<any>();
    private ContactUpdate = new Subject<any>();
    private UpdateContactData = new Subject<any>();
    public ReferralViewData: any;
    public ReferralId: any;
    public GeneralReceiptView = new Subject<any>();
    public PaymentView = new Subject<any>();
    public UserRightsView = new Subject<any>();
    public reportLableName: any;
    // private dataSource = new BehaviorSubject({});
    // data = this.dataSource.asObservable();
    // FiTab1Details: any
    // private FiTab1Data = new Subject<any>()
    ValidationErrorMessages = {}
    errormessages: any
    datevalue: any;
    year: any;
    month: any;
    day: any;
    newDate: any;

    pCreatedby: any;
    pStatusname = 'ACTIVE';
    ptypeofoperation = 'CREATE';
    comapnydetails: any;
    pageSize = 10;
    private ValidationStatus = new Subject<any>()
    _validationStatus: any;
    IpAddress: any;


    public extractData(res: Response) {

        let body = res;
        return body;
    }
    GetImage(strPath)
    {
        const params = new HttpParams().set('strPath', strPath)
        return this.callGetAPI('/loans/masters/contactmasterNew/ConvertImagepathtobase64', params, 'YES')
    }
   
    GetBankNames() {
        try {
          return this.callGetAPI('/Accounting/Masters/GetBankNames', ' ', 'NO');
        }
        catch (e) {
          this.showErrorMessage(e);
        }
      }
    public handleError(error: Response | any) {
        console.error(error.message || error);
        return Observable.throw(error.message || error);
    }
    showErrorMessage(errormsg: string) {
        this.toastr.error(errormsg, "Error!");
    }
    showErrorMessageForLessTime(errormsg: string) {
        this.toastr.error(errormsg, "Error!", { timeOut: 2500 });
    }
    showInfoMessage(errormsg: string) {

        this.toastr.success(errormsg, "Success!");
    }
    constructor(private datepipe: DatePipe, private http: HttpClient, private toastr: ToastrService) {
        
        this._validationStatus = false;
        let Urc = sessionStorage.getItem("Urc");
        if (Urc == null) {

            this.pCreatedby = 0;

        }
        else {
            this.pCreatedby = JSON.parse(sessionStorage.getItem("Urc"))["pUserID"];
        }

        this.comapnydetails = JSON.parse(sessionStorage.getItem("companydetails"));

        //if (this.comapnydata == null) {
        //    this.pCompanyName = '';
        //    this.pAddress1 = '';
        //    this.pAddress2 = '';
        //    this.pcity = '';
        //    this.pCountry = '';
        //    this.pState = '';
        //    this.pDistrict ='';
        //    this.pPincode ='';
        //    this.pCinNo = '';
        //    this.pGstinNo = '';
        //    this.pBranchname = '';
        //}
        //else {
        //    this.pCompanyName = this.comapnydata['pCompanyName'];
        //    this.pAddress1 = this.comapnydata['pAddress1'];
        //    this.pAddress2 = this.comapnydata['pAddress2'];
        //    this.pcity = this.comapnydata['pcity'];
        //    this.pCountry = this.comapnydata['pCountry'];
        //    this.pState = this.comapnydata['pState'];
        //    this.pDistrict = this.comapnydata['pDistrict'];
        //    this.pPincode = this.comapnydata['pPincode'];
        //    this.pCinNo = this.comapnydata['pCinNo'];
        //    this.pGstinNo = this.comapnydata['pGstinNo'];
        //    this.pBranchname = this.comapnydata['pBranchname'];
        //}
        // 
        //this.apiURL = appsettings.ApiHostUrl;
        let ipaddress = sessionStorage.getItem("IPAddress");
        if (ipaddress != null) {
          this.IpAddress = JSON.parse(sessionStorage.getItem("IPAddress"))["ip"];
          console.log("ip address",this.IpAddress)
        }
        else {
          this.IpAddress = '';
        }
    }

    _setCompanyDetails() {

        this.comapnydetails = JSON.parse(sessionStorage.getItem("companydetails"));
    }

    _setPcreatedby() {

        this.pCreatedby = JSON.parse(sessionStorage.getItem("Urc"))["pUserID"];
    }
    public getIPAddress() {
    
        return this.http.get("http://cors-anywhere.herokuapp.com/http://api.ipify.org?format=json");
      }
      _setIPAddress() {
        this.IpAddress = JSON.parse(sessionStorage.getItem("IPAddress"))["ip"];
      }
    getValidationMessage(formcontrol: AbstractControl, errorkey: string, lablename: string, key: string, skipKey: string): string {
        let errormessage;
      //else if
        if (errorkey == 'required')
            errormessage = lablename + ' Required';
        if (errorkey == 'email' || errorkey == 'pattern')
            errormessage = 'Invalid ' + lablename;
        if (errorkey == 'minlength') {

            let length = formcontrol.errors[errorkey].requiredLength;
            errormessage = lablename + ' Must Have ' + length + ' Digits';
        }
        if (errorkey == 'maxlength' && key != skipKey) {

            let length = formcontrol.errors[errorkey].requiredLength;
            errormessage = lablename + ' Must Have ' + length + ' Digits';
        }
        if (errorkey == 'maxlength' && key == skipKey) {

            //let length = formcontrol.errors[errorkey].requiredLength;
            errormessage = 'Invalid ' + lablename;
        }
        if (errorkey == 'max') {
            let maxValue = formcontrol.errors.max.max;
            errormessage = lablename + ' Maximum value can be ' + maxValue;
        }

        return errormessage;
    }

    formatDateFromDDMMYYYY(value: any): Date | null {

        value = value.substr(0, 10);
        if (value != '' && value != null) {
            if (value.indexOf('/') > -1) {
                this.datevalue = value.split('/');
            }
            if (value.indexOf('-') > -1) {
                this.datevalue = value.split('-');
            }
            if (value.indexOf(' ') > -1) {
                this.datevalue = value.split(' ');
            }
            //console.log("this.datevalue : ", this.datevalue);

            this.day = Number(this.datevalue[0]);
            this.month = Number(this.datevalue[1]) - 1;
            this.year = Number(this.datevalue[2]);

            this.newDate = new Date(this.year, this.month, this.day);
            //console.log("this.newDate : ", this.newDate);

            return this.newDate;
        } else {
            return null
        }

    }
    formatDateFromYYYYMMDD(value: any): Date | null {

        value = value.substr(0, 10);
        if (value != '' && value != null) {
            if (value.indexOf('/') > -1) {
                this.datevalue = value.split('/');
            }
            if (value.indexOf('-') > -1) {
                this.datevalue = value.split('-');
            }
            if (value.indexOf(' ') > -1) {
                this.datevalue = value.split(' ');
            }

            this.day = Number(this.datevalue[2]);
            this.month = Number(this.datevalue[1]) - 1;
            this.year = Number(this.datevalue[0]);

            this.newDate = new Date(this.year, this.month, this.day);
            return this.newDate;
        } else {
            return null
        }

    }
    formatDateFromMMDDYYYY(value: any): Date | null {
      //undefined should be provide
        value = value.substr(0, 10);
        if (value != '' && value != null) {
            if (value.indexOf('/') > -1) {
                this.datevalue = value.split('/');
            }
            if (value.indexOf('-') > -1) {
                this.datevalue = value.split('-');
            }
            if (value.indexOf(' ') > -1) {
                this.datevalue = value.split(' ');
            }

            this.day = Number(this.datevalue[1]);
            this.month = Number(this.datevalue[0]) - 1;
            this.year = Number(this.datevalue[2]);

            this.newDate = new Date(this.year, this.month, this.day);
            return this.newDate;
        } else {
            return null
        }

    }
    getFormatDate(dateData: any): string | null {
        let data = this.datepipe.transform(dateData, 'dd/MM/yyyy');
        return data;
    }
    callGetAPI(apiPath, params, parameterStatus) {

        //let data = environment.apiURL;
        //if (parameterStatus.toUpperCase() == 'YES')
        //  return this.http.get(environment.apiURL + apiPath, { params }).map(this.extractData).catch(this.handleError);
        //else
        //  return this.http.get(environment.apiURL + apiPath).map(this.extractData).catch(this.handleError);
        let urldata = environment.apiURL;
        if (parameterStatus.toUpperCase() == 'YES')

            return this.http.get(urldata).pipe(
                mergeMap(json => this.http.get(json[0]['ApiHostUrl'] + apiPath, { params }).map(this.extractData).catch(this.handleError)));
        else
            return this.http.get(urldata).pipe(
                mergeMap(json => this.http.get(json[0]['ApiHostUrl'] + apiPath).map(this.extractData).catch(this.handleError)));

    }

    callPostAPI(apiPath, data) {

        let urldata = environment.apiURL;
        let httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        });
        httpHeaders.append('Access-Control-Allow-Origin', '/*');

        let options = {
            headers: httpHeaders
        };
        //console.log("data : ", data);

        //return this.http.post(environment.apiURL + apiPath, data, options).map(this.extractData).catch(this.handleError);
        return this.http.get(urldata).pipe(
            mergeMap(json => this.http.post(json[0]['ApiHostUrl'] + apiPath, data, options).map(this.extractData).catch(this.handleError)));

  }

  //Not used(Repeated)
    callPostAPIMultipleParameters(apiPath) {
        let urldata = environment.apiURL;
        let httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
        });
        httpHeaders.append('Access-Control-Allow-Origin', '/*');

        let options = {
            headers: httpHeaders
        };
        return this.http.get(urldata).pipe(
            mergeMap(json => this.http.post(json[0]['ApiHostUrl'] + apiPath, options).map(this.extractData).catch(this.handleError)));

    }


    public currencyformat(value) {
        //
        if (value == null) { value = 0; }
        else {
            value = parseFloat(value.toString().replace(/,/g, ""));
        }
        let withNegativeData: any;
        var result: any;
        //let currencyformat= this.cookieservice.get("savedformat")
        let currencyformat = "India"
        if (currencyformat == "India") {
            if (value < 0) {
                let stringData = value.toString();
                withNegativeData = stringData.substring(1, stringData.length);
                result = withNegativeData.toString().split('.');
            }
            else if (value >= 0) {
                result = value.toString().split('.');
            }
            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
            if (result.length > 1) {
                output += "." + result[1];
            }
            if (value >= 0) {
                return output
            }
            else if (value < 0) {
                output = '-' + '' + output;
                return output
            }
            // } 
        }
        else {
            // this.symbol = this.cookieservice.get("symbolofcurrency")
            var result = value.toString().split('.');
            var lastThree = result[0].substring(result[0].length - 3);
            var otherNumbers = result[0].substring(0, result[0].length - 3);
            if (otherNumbers != '')
                lastThree = ',' + lastThree;
            var output = otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
            if (result.length > 1) {
                output += "." + result[1];
            }
            //return this.symbol+"  "+output
        }

    }
    public functiontoRemoveCommas(value) {
        let a = value.split(',')
        let b = a.join('')
        let c = b
        return c;
    }
    public removeCommasInAmount(value) {
        if (isNullOrEmptyString(value))
            value = 0;
        return parseFloat(value.toString().replace(/,/g, ""))
        // let a = value.split(',')
        // let b = a.join('')
        // let c = b
        // return c;
    }
    GetContactDetails(contacttype: string) {

        const params = new HttpParams().set('contactType', contacttype)
        return this.callGetAPI('/Settings/ReferralAdvocate/getContactDetails', params, 'YES')
        //   let httpHeaders = new HttpHeaders({
        //     'Content-Type': 'application/json',
        //     'Cache-Control': 'no-cache'
        //   })
        //   let HttpParams = { 'contactType': contacttype }
        //   let options = {
        //     headers: httpHeaders,
        //     params: HttpParams
        //   };  
        // return this.http.get('http://192.168.2.164:9999/api/Settings/ReferralAdvocate/getContactDetails',options)
    }

    GetContactDetailsbyId(ContactId) {

        const params = new HttpParams().set('ContactId', ContactId)
        return this.callGetAPI('/Settings/ReferralAdvocate/GetContactDetailsbyId', params, 'YES')
    }
    ConvertImagepathtobase64(path) {
        const params = new HttpParams().set('strPath', path)
        return this.callGetAPI('/loans/masters/contactmaster/ConvertImagepathtobase64', params, 'YES')
    }

    SetFiTab1Data(data) {
        this.FiTab1Details = data
    }
    GetFiTab1Data() {
        return this.FiTab1Details
    }
    _SetFiTab1Data(data) {
        this.FiTab1Data.next(data)
    }
    _GetFiTab1Data(): Observable<any> {
        return this.FiTab1Data.asObservable();
    }

    _SetBankUpdate(data) {
        this.BankUpdate.next(data);
    }
    _GetBankUpdate(): Observable<any> {
        return this.BankUpdate.asObservable();
    }
    _SetBankData(data) {
        this.BankData.next(data);
    }
    _GetBankData(): Observable<any> {
        return this.BankData.asObservable();
    }
    /////mahesh m
    CheckValidationStatus(status) {
        this.ValidationStatus.next(status)
    }
    GetValidationStatus(): Observable<any> {
        return this.ValidationStatus
    }


    _setValidationStatus(data: any) {

        this._validationStatus = data
    }
    _getValidationStatus() {
        return this._validationStatus;
    }

    _checkValidationsBetweenComponents() {
        this.ValidationStatus.next();
    }
    _CheckValidationStatus(): Observable<any> {
        return this.ValidationStatus.asObservable();
    }
    /////
    _SetKYCData(data) {
        this.KYCData.next(data);
    }
    _GetKYCData(): Observable<any> {
        return this.KYCData.asObservable();
    }
    _SetKYCUpdate(data) {
        this.KYCUpdate.next(data);
    }
    _GetKYCUpdate(): Observable<any> {
        return this.KYCUpdate.asObservable();
    }
    _SetTDSData(data) {
        this.TDSData.next(data);
    }
    _GetTDSData(): Observable<any> {
        return this.TDSData.asObservable();
    }

    _SetTDSUpdate(data) {
        this.TDSUpdate.next(data);
    }
    _GetTDSUpdate(): Observable<any> {
        return this.TDSUpdate.asObservable();
    }

    _SetContactData(data) {
        this.ContactData.next(data);
    }
    _GetContactData(): Observable<any> {
        return this.ContactData.asObservable();
    }
    _SetContactUpdate(data) {
        this.ContactUpdate.next(data);
    }
    _GetContactUpdate(): Observable<any> {
        return this.ContactUpdate.asObservable();
    }

    _SetReferralViewData(data) {
        this.ReferralViewData = data;
    }
    _GetReferralViewData() {
        return this.ReferralViewData;
    }


    _SetGeneralReceiptView(data) {
        this.GeneralReceiptView = data
        // this.GeneralReceiptView.next(data);
    }
    _GetGeneralReceiptView() {
        return this.GeneralReceiptView
    }

    _SetPaymentView(data) {
        this.PaymentView = data
    }
    _GetPaymentView() {
        return this.PaymentView
    }


    _SetReferralid(data) {
        this.ReferralId = data;
    }
    _GetReferralid() {
        return this.ReferralId;
    }

    fileUpload(data) {
        let urldata = environment.apiURL;
        return this.http.get(urldata).pipe(
            mergeMap(json => this.http.post(json[0]['ApiHostUrl'] + '/loans/masters/contact/MultiFileUpload', data).map(this.extractData).catch(this.handleError)));
    }

    GetContactDetailsforKYC(ContactId) {

        const params = new HttpParams().set('pContactId', ContactId)
        return this.callGetAPI('/Settings/getDocumentstoreDetails', params, 'YES')
    }

    removeCommasForEntredNumber(enteredNumber) {
        return parseFloat(enteredNumber.toString().replace(/,/g, ""))
    }

    showWarningMessage(message) {
        this.toastr.warning(message, 'Warning!');
    }
    GetCollectionReport(fromDate, toDate,recordid,fieldname,fieldtype): Observable<any> {
        try {
            const params = new HttpParams().set('fromDate', fromDate).set('toDate', toDate).set('recordid', recordid).set('fieldname', fieldname).set('fieldtype', fieldtype);
            return this.callGetAPI('/CollectionReport/api/Loans/Reports/GetColletionsummary', params, 'YES');
        }
        catch (e) {
            this.showErrorMessage(e);
        }
    }
    _SetUserrightsView(data) {
        this.UserRightsView = data
        // this.GeneralReceiptView.next(data);
    }
    _GetUserrightsView() {
        return this.UserRightsView
    }


    GetCollectiondetails(fromDate, toDate, applicationid): Observable<any> {
        try {
            const params = new HttpParams().set('fromDate', fromDate).set('toDate', toDate).set('Applicationid', applicationid)
            return this.callGetAPI('/CollectionReport/api/Loans/Reports/GetColletiondetails', params, 'YES');
        }
        catch (e) {
            this.showErrorMessage(e);
        }
    }


    _setReportLableName(data) {
        
        this.reportLableName = data;
    }
    _getReportLableName() {
        
        return this.reportLableName;
    }

    Getmemberdetails(): Observable<any> {
        try {
            return this.callGetAPI('/Banking/Masters/MemberType/GetMemberDetails', '', 'NO');
        }
        catch (e) {
            this.showErrorMessage(e);
        }
  }
  ageCalculatorYYYYMMDD(fromDate, Todate): string {
    let Currentage = "";
    if (!isNullOrEmptyString(fromDate) && !isNullOrEmptyString(Todate)) {
      let start = new Date(fromDate);
      let end = new Date(Todate)

      let b_day = start.getDate();
      let b_month = start.getMonth() + 1;
      let b_year = start.getFullYear();

      let c_day = end.getDate();
      let c_month = end.getMonth() + 1;
      let c_year = end.getFullYear();
      
      if (b_day > c_day) {
        c_day = c_day + this.daysInMonth(c_month - 1, c_year);
        c_month = c_month - 1;
      }
      if (b_month > c_month) {
        c_year = c_year - 1;
        c_month = c_month + 12;
      }

      let calculated_date = c_day - b_day;
      let calculated_month = c_month - b_month;
      let calculated_year = c_year - b_year;

      Currentage = calculated_year + " Year  " + calculated_month + "  Months " + calculated_date + "  Days";

    }
    return Currentage;
  }
  daysInMonth(month, year) {
    if (month < 0)
      return 31;
    return new Date(year, month, 0).getDate();
  }
  ageCalculation(DOB): Number {


    let age;
    let dob = DOB;
    if (dob != '' && dob != null) {
      let currentdate = Date.now();
      //let agedate = new Date(dob);
      let agedate = new Date(dob).getTime();
      let timeDiff = Math.abs(currentdate - agedate);
      if (timeDiff.toString() != 'NaN')
        age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
      else
        age = 0;
    }
    else {
      age = 0;
    }
    return age;

  }
  ConvertfirebaseDataToArray = (Data: any) => {
    const returnArr = [];
   
    Data.forEach((childData: any) => {
      const item = childData.val();
      item.key = childData.key;
      returnArr.push(item);
    });
  
    return returnArr.sort();
  };
   convertMinToSec = (str) => {
    const [ mm = '0', ss = '0'] = (str || '0:0').split(':');
    const minute = parseInt(mm, 10) || 0;
    const second = parseInt(ss, 10) || 0;
    return (minute*60) + (second);
 };
}

