import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ControlContainer } from '@angular/forms';
import { CommonService } from '../../../Services/common.service';
import { MenuModulesService } from '../../../Services/Settings/menu-modules.service';
import { from } from 'rxjs';
import { AngularWaitBarrier } from 'blocking-proxy/built/lib/angular_wait_barrier';
import { Router, Route } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { Alert } from 'selenium-webdriver';
import { State, process } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
import { IfStmt } from '@angular/compiler';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';

declare let $: any
@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styles: []
})
export class AddMenuComponent implements OnInit {
  @ViewChild(DataBindingDirective, { static: true }) dataBinding: DataBindingDirective;
  
  constructor(private fb: FormBuilder, private _CommonService: CommonService, private _MenuModulesService: MenuModulesService, private router: Router, private toastr: ToastrService) {
    
    this.allData = this.allData.bind(this);
   }
  loading=false;
  AddModuleForm: FormGroup;
  ModuleForm: FormGroup;

  IsCapsWarning: Boolean;
  Modulesdata: any;
  Navigationdata:any;
  Navigationtempdata:any;
  SubModulesdata:any;
  routertep: any = [];
  allroters: any = [];
  modalid:any;
  modalname: any;
  officersIds: any=[]
  submodalid:any;
  submodalname: any;
  submitted=false;
  issubmitted=false;
  public pageSize = 10;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10
  };
  public headerCells: any = {
    textAlign: 'center'
  };
  ngOnInit() {
    this.submitted=false;
    this.AddModuleForm = this.fb.group({
      moduleName: ['',Validators.required],
      moduleId:[''],    
      functionname:['',Validators.required],
      functionurl:['',Validators.required],
      functionsortorder:[''],
     
    });

    this.ModuleForm = this.fb.group({
      moduleId: [''],
      moduleName: ['',Validators.required]
    });

  
    
    this.GetallModules();
    this.GetNavigation();
    
   debugger
    this.routertep = this.router.config[5].children;
    //this.officersIds =;
    var componentNames=[];
    this.routertep.forEach(function (officer) {
      componentNames.push({'path':officer.path,'componentName':officer.component.name});
    });
    this.officersIds=componentNames
    // $("#contactsaa").kendoMultiColumnComboBox({
    //   dataTextField: "path",
    //   dataValueField: "path",
    //   height: 400,
    //   columns: [
      
    //     { field: "path", title: "Path Name", width: 200 },
    //     { field: "componentName", title: "Component Name", width: 500 },

    //   ],
    //   footerTemplate: 'Total #: instance.dataSource.total() # items found',
    //   filter: "contains",
    //   filterFields: ["path"],
    //   dataSource:this.officersIds,
    //   select: this.SelectPath,
    //  // change: this.onChange,

    // });

  }
  GetallModules() {
    
    this._MenuModulesService.GetModules().subscribe(data => {
      this.Modulesdata = data;
      console.log("module",this.Modulesdata)
    });
  }
  GetNavigation(){
    this.loading=true;
   this._MenuModulesService.GetNavigation().subscribe(data => {
      this.Navigationdata = data;
      this.Navigationtempdata=this.Navigationdata;
      this.loading=false;
      console.log(this.Navigationdata)
    });
  }

  OpenModel() {
    this.submitted=false;
    this.issubmitted = false;
    this.ModuleForm.reset();
    $('#add-detail').modal('show');
  }
  ModelSave() 
  {
    debugger
    this.issubmitted = true;
    if (this.ModuleForm.valid) {
   
      this.ModuleForm.controls.moduleId.setValue(0)
    var data = JSON.stringify(this.ModuleForm.value);
   
    
     console.log(data)

    this._MenuModulesService.saveModuleTitle(data).subscribe(res => {
      this.GetallModules();
      this.toastr.success("Saved Successfully", "Success");
      $('#add-detail').modal('hide');
    });

     
    
 
    }
   

  }
  SelectModule(modaldata :any)
  {
    debugger
  this.modalid = modaldata.moduleId
  this.modalname= modaldata.moduleName;
  
  //this.loadSubModule(this.modalid);
  }

  
 
 

  
    selectedValue:any
    SelectPath(path){
      debugger
      if(path!=undefined){
        this.selectedValue=path.path.toString();
        localStorage.setItem('Value',this.selectedValue);
      }
      else{
        localStorage.setItem('Value','');
      }
      
     }
    
  AddMenu(){
    debugger
    this.submitted = true;
   
     if(this.AddModuleForm.valid)
     {
       
     
      this.AddModuleForm.controls.moduleId.setValue(this.modalid);
      this.AddModuleForm.controls.moduleName.setValue(this.modalname);
    
      var pathname =localStorage.getItem('Value');
      if(pathname!=''){
        pathname='/'+pathname;
        this.AddModuleForm.controls.functionurl.setValue(pathname);
        localStorage.setItem('Value','');
        // let ShowinNavigation=this.AddModuleForm.value['pIsfunctionshowinNavigation'];
        // if(ShowinNavigation==''){
        //   this.AddModuleForm.controls.pIsfunctionshowinNavigation.setValue(false);
        // }
        // let AllowRoles=this.AddModuleForm.value['pIsFunctionshowinRoles'];
        // if(AllowRoles==''){
        //   this.AddModuleForm.controls.pIsFunctionshowinRoles.setValue(false);
        // }
    
        var data = JSON.stringify(this.AddModuleForm.value);
        console.log(data)
        this._MenuModulesService.saveMenu(data).subscribe(res => {
          this.AddModuleForm.reset();
          this.submitted = false;
          this.GetNavigation()
          console.log(res)
          this.toastr.success("Saved Successfully", "Success")
              
        });
      }
      else{
        this.toastr.info("Select Form Url", "Info");
      }
    }
 
  }

  NavigationDelete(dataItem){
  let createdby=this._CommonService.pCreatedby;
  let pFunctionId=dataItem.pFunctionId;
  let ptypeofoperation='delete';
  let data = JSON.stringify({ "pCreatedby":createdby,"pFunctionId": pFunctionId, "ptypeofoperation": ptypeofoperation});
  this._MenuModulesService.DeleteMenu(data).subscribe(res => {
    this.toastr.success("Menu deleted successfully", "Success");
    this.GetNavigation();    
  });
  }
  ClearAddMenu(){
    this.submitted=false;
    this.issubmitted = false;
    this.modalname ='';
    this.ModuleForm.reset();
   
    this.AddModuleForm.reset();
  }
  // ModuleTitle(event) {
  //   if (event.getModifierState("CapsLock")) {
  //     this.IsCapsWarning = true
  //   } else {
  //     this.IsCapsWarning = false
  //   }
  // }


  get errorsModuleForm() { return this.ModuleForm.controls; }
 
  get errorsAddModuleForm() { return this.AddModuleForm.controls; }
  CloseModel() {
    this.ModuleForm.reset()
   
    $('#add-detail').modal('hide');
    $('#add-submodule').modal('hide');
  }

  Clear() {
    this.submitted=false;
    this.issubmitted = false;
    this.ModuleForm.reset();
   
  }
  onFilter(inputValue: string) {
    this.Navigationdata = process(this.Navigationtempdata, {
      filter: {
        logic: "or",
        filters: [
          {
            field: 'moduleName',
            operator: 'contains',
            value: inputValue
          },
          {
            field: 'functionname',
            operator: 'contains',
            value: inputValue
          },
          
        ],
      }
    }).data;
    this.dataBinding.skip = 0;
  }

  public allData(): ExcelExportData {
  
    const result: ExcelExportData = {
      data: process(this.Navigationdata, {  sort: [{ field: 'pVchapplicationid', dir: 'desc' }] }).data
    };

    return result;
  }
}
