import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { HttpHeaders, HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private _CommonService: CommonService) { }


  GetAuctionUserModule(UserName)
  {
    console.log("menu dynamic",UserName)
    const params = new HttpParams().set('UserName',UserName)
     return this._CommonService.callGetAPI('/Settings/Users/UserRights/GetAuctionUserModule', params, 'YES');
  }
  test(){

  }
}
