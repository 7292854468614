import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuctionHistoryService } from 'src/app/Services/Reports/auction-history.service';
import { CommonService } from 'src/app/Services/common.service';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { GroupDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-chitunauthorization',
  templateUrl: './chitunauthorization.component.html',
  styles: []
})
export class ChitunauthorizationComponent implements OnInit {
  branchname: any;
  groupcode: any;
  groupcodedetails:any=[]
  chitUnauthorizeddata:any=[];
  branchnames:any=[];
  public groups: GroupDescriptor[] = [
    { field: "groupcode" },
 ];
  constructor(private formbuilder:FormBuilder,private auctionservice:AuctionHistoryService,
    private _CommonService:CommonService,private hallallotmentservice:HallAllotmentService) { }
    ChitUnAuthorizationReport:FormGroup;
    ChitUnAuthorizationerrors:any;
    branchdetails:any;
    showbranch:boolean=false
  ngOnInit() 
  {
    this.GetformData();
    this.GetBranchdetails();
    this.BlurEventAllControll(this.ChitUnAuthorizationReport)
  }
  public GetformData() {
    this.ChitUnAuthorizationerrors = {}
    this.ChitUnAuthorizationReport = this.formbuilder.group({
    
    
      groupcode:[null,Validators.required],
      branchname:[null,Validators.required]
     
    });
   
  }
  GetBranchNames()
  {
     this.hallallotmentservice.GetBranchNames().subscribe(json=>{
         if(json)
         {
           console.log("branch names",json)
           this.branchnames=json
         }
     })
  }
  Branchchange(event)
  {
    if(event!=undefined)
    {
      this.branchname=event.branchname;
      this.GetGroupCodes()
    }
    else{
      this.chitUnauthorizeddata=[];
      
      this.ChitUnAuthorizationReport.controls.groupcode.setValue(null);
      this.groupcodedetails=[];
      this.ChitUnAuthorizationerrors={}
    }
  }
 GetBranchdetails()
 {
   this.branchdetails=this.hallallotmentservice.GetBranchDetails()
   console.log(this.branchdetails);
   this.branchname=this.branchdetails.pBranchName;
   if(this.branchname=="Admin")
   {
     this.showbranch=true
     this.GetBranchNames()
   }
   else{
    this.GetGroupCodes();
    this.showbranch=false;
    let branch = <FormGroup>this.ChitUnAuthorizationReport['controls']['branchname'];
  
    branch.clearValidators()
    branch.updateValueAndValidity()
  
   }
  
 }
 GetGroupCodes()
  {
    debugger
    if(this.branchname!=undefined)
    {
      this.auctionservice.GetAuthorizedGroupcodes(this.branchname).subscribe(json=>{
        this.groupcodedetails=json
      })
    }
     
    
  
  }
 GroupChange(event)
  {
    if(event!=undefined)
    {
      this.groupcode=event.groupcode
    }
    else{
      this.chitUnauthorizeddata=[];
      this.ChitUnAuthorizationerrors={}
    }
  }
  generatereport()
  {
    
    let isValid: boolean = true;
    if(this.checkValidations(this.ChitUnAuthorizationReport,isValid))
    {
      this.auctionservice.GetChitUnAuthorizedData(this.branchname,this.groupcode).subscribe(json=>{
      this.chitUnauthorizeddata=json
    
        console.log(json);
      })
    }
  }
 checkValidations(group: FormGroup, isValid: boolean): boolean {
  try {
    Object.keys(group.controls).forEach((key: string) => {
      isValid = this.GetValidationByControl(group, key, isValid);
    })
  }
  catch (e) {
    return false;
  }
  return isValid;
}

GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {
  try {

    let formcontrol;
    formcontrol = formGroup.get(key);
    if (formcontrol) {
      if (formcontrol instanceof FormGroup) {
        this.checkValidations(formcontrol, isValid)
      }
      else if (formcontrol.validator) {
        this.ChitUnAuthorizationerrors[key] = '';
        if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

          let errormessage;
          for (const errorkey in formcontrol.errors) {
            if (errorkey) {
              let lablename;
              lablename = (document.getElementById(key) as HTMLInputElement).title;
              errormessage = this._CommonService.getValidationMessage(formcontrol, errorkey, lablename, key, '');
              this.ChitUnAuthorizationerrors[key] += errormessage + ' ';
              isValid = false;
            }
          }
        }
      }
    }
  }
  catch (e) {
    return false;
  }
  return isValid;
}
BlurEventAllControll(fromgroup: FormGroup) {
  try {
    Object.keys(fromgroup.controls).forEach((key: string) => {
      this.setBlurEvent(fromgroup, key);
    })
  }
  catch (e) {
    return false;
  }
}
setBlurEvent(fromgroup: FormGroup, key: string) {
  try {
    let formcontrol;
    formcontrol = fromgroup.get(key);
    if (formcontrol) {
      if (formcontrol instanceof FormGroup) {
        this.BlurEventAllControll(formcontrol)
      }
      else {
        if (formcontrol.validator)
          fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
      }
    }
  }
  catch (e) {
    return false;
  }
}
}
