import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';


export const snapshotToArray = (snapshot: any) => {
  const returnArr = [];
 
  snapshot.forEach((childSnapshot: any) => {
    const item = childSnapshot.val();
    item.key = childSnapshot.key;
    returnArr.push(item);
  });

  return returnArr;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.html',

})
export class DashboardComponent implements OnInit {

  
  constructor() {
    
  }

  ngOnInit() {
   

  }
 
 
  

  




}
  // Test dashboard -- Test




