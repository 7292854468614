import { Component } from '@angular/core';
import * as firebase from 'firebase';
const config = {
  //rao chits live
  //  apiKey: "AIzaSyCwQ77XoGc-tSNOflKIhp6DYJcQA5-prYs",
   // databaseURL: "https://raochits-default-rtdb.firebaseio.com",

     //rao chits qc
  //  apiKey: "AIzaSyBQjxBemhJqoDUDWYe4WUyesRuf8gH_6Kg",
   // databaseURL: "https://raochits-auction-qc-default-rtdb.firebaseio.com",

     //rao chits dev
  //  apiKey: "AIzaSyBtoER3jmKkdhjdTOvK7Ra44_EfpoljNQQ",
   // databaseURL: "https://raochits-auction-dev-default-rtdb.firebaseio.com",

    //kanakadurga chits live

     //  apiKey: "AIzaSyDjWpOJLaIyqcBWU1ZccGRJ0gwgMqdY7Z4",
     //  databaseURL: "https://kanakadurgachits-f25bd-default-rtdb.firebaseio.com",


    //kanakadurga chits qc

   // apiKey: "AIzaSyCLvioShQmOnhQVowxefsSDwfWL9S9E2Fg",
   // databaseURL: "https://kanakadurgachits-auction-qc-default-rtdb.firebaseio.com",



  //kanakadurga chits dev

  // apiKey: "AIzaSyDiiz413j45tceJnxygC6qeu2IT3IPddS8",
  // databaseURL: "https://kanakadurgachits-dev-default-rtdb.firebaseio.com",








// jaya priya group

   apiKey: "AIzaSyAMBgeEqXH6t4Eogl6zGICWyjr7f_ZF3Zg",
   databaseURL: "https://jayagroup-2dab4-default-rtdb.firebaseio.com",
  
};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'finsta-v1';
  constructor() {
    firebase.initializeApp(config);
  }


  

}


