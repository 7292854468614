import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class LivebiddingService {
  ticketdata:any={}
  drawticketnumbers: any;
  auctionername:any;
  public spinner = new Subject<any>();
  meetingid: any;
  groupcode: any;
  status:any;
  data: any;
  idToLandOn: any;
  ReviwerGroup: any;
  Reviwerbranch: string;
  constructor(private commonService:CommonService) { }
  GetSubscriberDetails(chitno): Observable<any> {
    try {
      const params = new HttpParams().set('ChitNo', chitno);
      return this.commonService.callGetAPI('/Auctions/Livebidding/GetSubscriberDetails', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }

  Getdenominations(ChitNo): Observable<any> {
    try {
      const params = new HttpParams().set('chitno',ChitNo);
      return this.commonService.callGetAPI('/Auctions/Livebidding/GetDenonimation', params, 'YES');

    }
    catch (e) {
      this.commonService.showErrorMessage(e);
    }
  }
 StartAuction(groupcode,actionhall,branchname) {
  let path = '/Auctions/Livebidding/StartAuction?groupcode=' + groupcode + '&actionhall=' + actionhall+'&branchname=' + branchname
  return this.commonService.callPostAPIMultipleParameters(path);
  }
SetTicketDetails(groupcode,data,auctionstatus)
{
   this.groupcode=groupcode;
   this.data=data;
   this.status=auctionstatus
 this.ticketdata={groupcode:this.groupcode,checkeditems:this.data,auctionstatus:this.status}
 localStorage.setItem("groupdetails",JSON.stringify(this.ticketdata));
   }
   
  //this.ticketdata=data

GetTicketDetails()
{
  this.ticketdata=JSON.parse(localStorage.getItem("groupdetails"));
return this.ticketdata
}
GetGroupAuctionDetails(groupcode,Branchname)
{
  try {
    const params = new HttpParams().set('groupcode', groupcode).set('Branchname',Branchname);
    return this.commonService.callGetAPI('/Auctions/Livebidding/GroupAuctionDetails', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
SaveLiveAuctionHistory(data)
{
  return this.commonService.callPostAPI('/Auctions/Livebidding/SaveLiveAuctionHistory', data);
}
UpdateLiveAuctionHistory(groupcode,meetingid,comments) {
  let path = '/Auctions/Livebidding/UpdateLiveAuctionHistory?groupcode=' + groupcode + '&meetingid=' + meetingid+ '&comments=' + comments
  return this.commonService.callPostAPIMultipleParameters(path);
  }
InitiateAuction(groupcode,hallname,branchid): Observable<any> {
  try {
    const params = new HttpParams().set('groupcode', groupcode).set('hallname', hallname).set('branchid', branchid);
    return this.commonService.callGetAPI('/Auctions/HallAllotment/InitiateAuction', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
GetBranchNames()
{
  try {
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetBranchnames', '', 'NO');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
TodayAuctionsCount()
{
  try {
    return this.commonService.callGetAPI('/Auctions/Livebidding/TodayAuctionsCount', '', 'NO');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
GetReviewerBranchnames(type)
{
  try {
    const params = new HttpParams().set('type', type);
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetReviewerBranchnames', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
GetRunningAuctiondata(Branchname,type)
{
  try {
    const params = new HttpParams().set('Branchname', Branchname).set('type',type);
    return this.commonService.callGetAPI('/Auctions/Livebidding/GetReviewerData', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
SaveDueparticipants(data)
{
  return this.commonService.callPostAPI('/Auctions/Livebidding/SaveDueParticipants', data);
}
GetReferenceNocount(referenceno,branchname)
{
  try {
    const params = new HttpParams().set('referenceno', referenceno).set('branchname', branchname);
    return this.commonService.callGetAPI('/Auctions/Livebidding/CheckReferencenoexist', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
setDrawticketnumbers(data){
  this.drawticketnumbers=data;
}
getDrawticketnumbers(){
 return this.drawticketnumbers;
}
setAuctioneer(autioneername)
{
  this.auctionername=autioneername
}
getAuctioneer()
{
  return this.auctionername
}
setMeetingid(data){
this.meetingid=data;
}
getMeetingid(){
  return this.meetingid;
  }
  SetReviwerGroup(groupcode)
{
 localStorage.setItem("ReviwerGroup",groupcode);
   }
   SetReviwerbranch(branch)
   {
 localStorage.setItem("ReviwerBranch",branch);
   }
   GetReviwerbranch()
   {
     this.Reviwerbranch=localStorage.getItem("ReviwerBranch");
   return this.Reviwerbranch;
   }
   GetReviwerGroup()
   {
     this.ReviwerGroup=localStorage.getItem("ReviwerGroup");
   return this.ReviwerGroup;
   }
   ClearParticipantDue(ChitNo,mobileno)
{
  try {
    const params = new HttpParams().set('ChitNo', ChitNo).set('mobileno', mobileno);
    return this.commonService.callGetAPI('/Auctions/Livebidding/ClearParticipantDue', params, 'YES');

  }
  catch (e) {
    this.commonService.showErrorMessage(e);
  }
}
}
