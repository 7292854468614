import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styles: []
})
export class TestComponent implements OnInit {
  status = 'ready';
  notify: string;
  @ViewChild('countdown',{ static: false }) counter: CountdownComponent;
  notifyConfig: CountdownConfig = { leftTime: 10, notify: [2, 5] };
  constructor() { }

  ngOnInit() {
    debugger;
    console.log("hi");
  }
  start() {
    this.status = 'started';
    this.counter.begin();
  }

  finishTest() {
    console.log("count down", this.counter);
    setTimeout(() => this.counter.restart());
    this.status = 'restarted';
  }

  resetTimer() {
    this.counter.restart();
  }
  handleEvent2(e: CountdownEvent) {
    
    this.notify = e.action.toUpperCase();
    if (e.action === 'notify') {
      this.notify += ` - ${e.left} ms`;
    }
    console.log('Notify', e);
  }


}


