import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { CommonService } from 'src/app/Services/common.service';
import { IfStmt } from '@angular/compiler';

@Component({
  selector: 'app-reschedule',
  templateUrl: './reschedule.component.html',
  styles: []
})
export class RescheduleComponent implements OnInit {
  branch: any;
  time: string;
  min: Date = new Date();
  constructor(private datepipe:DatePipe,private fb:FormBuilder,
    private hallallotmentservice:HallAllotmentService,private _CommonService:CommonService) { }
Reschedule:FormGroup;
RescheduleErrors:any;
branchdetails:any=[];
groupcodedetails:any=[]
  ngOnInit()
   {
     this.GetformData();
    
     this.GetReAuctionBranches();
    this.BlurEventAllControll(this.Reschedule)
  }
  public GetformData() {
    this.RescheduleErrors = {}
    
    this.Reschedule = this.fb.group({
    
      branchName: [null, Validators.required],
      groupcode:[null,Validators.required],
      value:[new Date()],
      auctiontime: [null],
     
    });
   
  }
  GetReAuctionBranches()
  {
     this.hallallotmentservice.GetReAuctionBranches().subscribe(json=>
      {
        console.log("bramchdetails",json)
        this.branchdetails=json;
        this.branchdetails=json;
        let time=this.branchdetails[0].currentdatetime;
        this.min=new Date(time);
        this.Reschedule.controls.value.setValue(new Date(time));
        this.time=(this.datepipe.transform(time,'HH:mm'))
        this.Reschedule.controls.auctiontime.setValue(this.time)
     })
  }

  Branchchange(event)
  {
    debugger
    if(event!=undefined)
    {
      this.Reschedule.controls.groupcode.setValue(null);
      this.RescheduleErrors={}
      this.branch=event.branchName
      this.GetGroupCodes()
    }
    else{
      this.Reschedule.controls.groupcode.setValue(null);
      this.RescheduleErrors={}
    }
  }
  GetGroupCodes()
  {
    if(this.branch!=undefined)
    {
      this.hallallotmentservice.GetRescheduleGroups(this.branch).subscribe(json=>{
      debugger;
        this.groupcodedetails=json
        console.log("groupdetails",json)
        if(this.groupcodedetails.length>0){
         
          let time=this.groupcodedetails[0].currentdatetime;
          this.Reschedule.controls.value.setValue(new Date(time));
          this.time=(this.datepipe.transform(time,'HH:mm'))
          this.Reschedule.controls.auctiontime.setValue(this.time)
          this.min=new Date(time);
        }
      
      })
    }
  }
  onChange(test)
  {
    debugger;
     this.time=(this.datepipe.transform(test,'HH:mm'))
     this.Reschedule.controls.auctiontime.setValue(this.time)
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {
      return false;
    }
    return isValid;
  }

  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {
    try {

      let formcontrol;
      formcontrol = formGroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.RescheduleErrors[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;
            for (const errorkey in formcontrol.errors) {
              if (errorkey) {
                let lablename;
                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this._CommonService.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.RescheduleErrors[key] += errormessage + ' ';
                isValid = false;
              }
            }
          }
        }
      }
    }
    catch (e) {
      return false;
    }
    return isValid;
  }
  BlurEventAllControll(fromgroup: FormGroup) {
    try {
      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })
    }
    catch (e) {
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {
    try {
      let formcontrol;
      formcontrol = fromgroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }
    }
    catch (e) {
      return false;
    }
  }
  Save()
  {
    debugger
    let isValid: boolean = true;
    if(this.time==undefined)
    {
      this.time=(this.datepipe.transform(new Date(),'HH:mm'))
      this.Reschedule.controls.auctiontime.setValue(this.time)
    }
    let data=JSON.stringify(this.Reschedule.value)
    console.log(data)
    if(this.checkValidations(this.Reschedule,isValid))
    {
     
    //  else{
        let data=JSON.stringify(this.Reschedule.value)
        console.log(data)
        this.hallallotmentservice.SaveReschedule(data).subscribe(json=>{
        console.log(json);
        if(json)
        {
          this._CommonService.showInfoMessage("Saved Successfully");
          this.GetformData();
          this.time=undefined
  
        }
      
     
        })
     // }
     
    }
  }
}

