import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/Services/common.service';
import { LivebiddingService } from 'src/app/Services/livebidding.service';
import { isNullOrUndefined } from 'util';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { DataBindingDirective } from '@progress/kendo-angular-grid';
@Component({
  selector: 'app-reviewer',
  templateUrl: './reviewer.component.html',
  styleUrls: ['./reviewer.component.css']
})
export class ReviewerComponent implements OnInit {
  Reviewerform:FormGroup;
  public groups: GroupDescriptor[] = [{
    field: 'branch'},
  ];
  BranchList:any=[]
  AuctionsList:any=[];
  branchname:any;
  running: any;
  
  pending: any;
  totalauctions: any;
  completed: any;
  type: string;
  @ViewChild(DataBindingDirective, { static: true }) directive;
  constructor(private formBuilder:FormBuilder,private livebiddingservice:LivebiddingService,
    private commonservice:CommonService,private router: Router) { }

  ngOnInit() {
    this.Reviewerform = this.formBuilder.group({
      branchname: ["Select"],
    });
    this.TodayAuctionsCount();
    this.type="Running"
   this.GetBranchNames();
    setInterval(()=>{this.TodayAuctionsCount(),this.GetBranchNames(),this.BindReviewData()},1000)
    
  }
  TodayAuctionsCount()
  {
    this.livebiddingservice.TodayAuctionsCount().subscribe(json=>{
      console.log(json);
      
      this.running=json['running']
      this.pending=json['pending']
      this.totalauctions=json['totalAuctions']
      this.completed=json['completed']
    })
  }
  GetBranchNames()
  {
    this.livebiddingservice.GetReviewerBranchnames(this.type).subscribe(json=>{
    
      this.BranchList=json
      
    })
  }
  Running()
  {
    this.cleargrid()
    this.type="Running"
    this.branchname="";
    this.directive.skip = 0;
    this.GetBranchNames()
  }
  TotalAuctions()
  {
    this.cleargrid()
    this.type="Total"
    this.branchname="";
    this.directive.skip = 0;
    this.GetBranchNames()
  }
  Pending()
  {
    this.cleargrid()
    this.type="Pending"
    this.branchname="";
    this.directive.skip = 0;
    this.GetBranchNames()
  }
  Completed()
  {
    this.cleargrid()
    this.type="Completed"
    this.branchname="";
    this.directive.skip = 0;
    this.GetBranchNames()
  }
  cleargrid()
  {
    this.Reviewerform.controls.branchname.setValue(null)
    this.AuctionsList=[]
  }
  BranchNamechange(event){
    debugger;
    if(!isNullOrUndefined(event)){
        this.branchname=event.branch;
      this.BindReviewData();
    }
    else{
      this.AuctionsList=[];
      this.branchname=null;
    }
  }
  BindReviewData(){
    console.log(this.branchname);
    if(!isNullOrUndefined(this.branchname)){
    this.livebiddingservice.GetRunningAuctiondata(this.branchname,this.type).subscribe(json=>{
      

        this.AuctionsList=json
        console.log(this.AuctionsList)
        
      })
  }
}
  Review(dataitem){
    debugger;
   this.livebiddingservice.SetReviwerGroup(dataitem.groupcode);
   this.livebiddingservice.SetReviwerbranch(dataitem.branch);
   window.open("/#/Livebidding", "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no,width=1800,height=1200" );
   //this.router.navigate(['/Livebidding'])
  }
}
