import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/common.service';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { Router } from '@angular/router';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styles: []
})
export class AuthenticationComponent implements OnInit {
  Authenticationform:FormGroup
  constructor(private formBuilder:FormBuilder,private commonservice:CommonService,
    private hallallotmentservice:HallAllotmentService,private router:Router) { }
  Authenticationvalidations:any;
  Authenticationid:any;
  OTPCode:any;
  otpdata:any=[]
  ngOnInit() 
  {
    debugger
    this.Authenticationvalidations={}
    this.Authenticationform = this.formBuilder.group({
     
      pAuthenticationid:['',Validators.required],
      pOTPCode:['',Validators.required],
     

    });
  
    this.BlurEventAllControll(this.Authenticationform)
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {

      this.showErrorMessage(e);
      return false;
    }
    return isValid;
  }
  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {

    try {
      let formcontrol;

      formcontrol = formGroup.get(key);

      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {

          //if (key != 'InsuranceMemberNomineeDetailsList')
          //  this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.Authenticationvalidations[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;

            for (const errorkey in formcontrol.errors) {
              if (errorkey) {

                let lablename;

                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this.commonservice.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.Authenticationvalidations[key] += errormessage + ' ';
                isValid = false;
              }
            }

          }
        }
      }

    }
    catch (e) {
      this.commonservice.showErrorMessage(key);
      return false;
    }
    return isValid;
  }
  showErrorMessage(errormsg: string) {
    this.commonservice.showErrorMessage(errormsg);
  }
  BlurEventAllControll(fromgroup: FormGroup) {

    try {

      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })

    }
    catch (e) {
      this.commonservice.showErrorMessage(e);
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {

    try {
      let formcontrol;

      formcontrol = fromgroup.get(key);


      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {

          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }

    }
    catch (e) {
      this.commonservice.showErrorMessage(e);
      return false;
    }



  }
 
  
 
  Login()
  {
    debugger
    let isValid:boolean=true;
    
    let authid=this.Authenticationform.controls.pAuthenticationid.value;
    this.Authenticationid=authid.trim();
    let otp=this.Authenticationform.controls.pOTPCode.value
    this.OTPCode=otp.trim()
    if(this.checkValidations(this.Authenticationform,isValid))
    {
       this.hallallotmentservice.AuthenticateOTP(this.Authenticationid,this.OTPCode).subscribe(json=>{
         console.log("otp",json)
        this.otpdata=json
        debugger;
         if(!isNullOrUndefined(this.otpdata.hallname))
         {
          //this.router.navigate(['/GroupSelection'])
          sessionStorage.setItem('currentUser', JSON.stringify(this.otpdata));
          let hallname=btoa(this.otpdata.hallname+','+this.otpdata.branchid+','+this.otpdata.branchname)
          sessionStorage.setItem('GroupEncryption', hallname);
          this.router.navigate(['/GroupSelection'], { queryParams: { id: hallname} });
         // this.hallallotmentservice.SetGroupData(this.otpdata)
         }
         else{
           this.commonservice.showWarningMessage("Authentication failed!")
         }
       })
    }
   
  }
}
