import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { AuctionHistoryService } from 'src/app/Services/Reports/auction-history.service';
import { CommonService } from 'src/app/Services/common.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';

@Component({
  selector: 'app-prizedsubscribers',
  templateUrl: './prizedsubscribers.component.html',
  styles: []
})
export class PrizedsubscribersComponent implements OnInit {
  public dpConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  date: any;
  month: any;
  branchdetails: any=[]
  public today: Date = new Date();
  branch: any;
  branchdata:any;
  branchname:any;
  groupcodedetails:any=[];
  Datetype:any=["Current Month","Last 3 months"]
  groupcode: any;
  prizedsubscribersdata: any=[]
  ip: any;
  constructor(private formbuilder:FormBuilder,private datePipe:DatePipe,private auctionservice:AuctionHistoryService,
    private _CommonService:CommonService,private hallallotmentservice:HallAllotmentService) 
  {
    this.dpConfig.dateInputFormat = 'DD-MMM-YYYY'
    this.dpConfig.maxDate = new Date();
    this.dpConfig.showWeekNumbers = false;
   }
   AuctionDataReport:FormGroup;
   AuctionDataReporterrors:any;
  ngOnInit() 
  {
    this.month=this.today
    this.date = this.datePipe.transform(this.month, "dd-MMM-yyyy");
    sessionStorage.getItem("ipaddress");
    console.log("ip address",sessionStorage.getItem("ipaddress"))
    this.GetformData()
    this.branchdata = this.hallallotmentservice.GetBranchDetails();
    console.log("branchdata",this.branchdata)
    if(this.branchdata.pType=='admin' || this.branchdata.pType=='reviewer')
    {
      this.branchname='All'
    }
    else if(this.branchdata.pType=="auctioneer"){
      this.branchname=this.branchdata.branchname
    }
    else{
      this.branchname=this.branchdata.pBranchName
    }
    this.GetBranchDetails()
    this.GetGroupCodes()
    this.BlurEventAllControll(this.AuctionDataReport)
  }
  public GetformData() {
    this.AuctionDataReporterrors = {}
    this.AuctionDataReport = this.formbuilder.group({
    
      branch: ['', Validators.required],
      groupcode:['',Validators.required],
      fromDate: [null,Validators.required],
     
    });
   
  }

  DateChange(event)
  {
    debugger
     this.month=event
    this.date = this.month
    this.AuctionDataReport.controls.branch.setValue(null);
    this.branchdetails=[];
    this.groupcodedetails=[]
    this.AuctionDataReport.controls.groupcode.setValue(null);
    this.prizedsubscribersdata=[];
    this.AuctionDataReporterrors={}
    this.GetBranchDetails()
  }
  GetBranchDetails()
  {
    debugger
     this.auctionservice.GetBranchDetails(this.date,this.branchname).subscribe(json=>{
       this. branchdetails=json
       console.log(this.branchdetails)
     })
  }
 
  Branchchange(event)
  {
    debugger
    if(event!=undefined)
    {
      this.AuctionDataReport.controls.groupcode.setValue(null)
      this.branch=event.branch;
      this.AuctionDataReporterrors={}
      this.GetGroupCodes()
    }
    else{
      this.AuctionDataReport.controls.groupcode.setValue(null)
      this.AuctionDataReporterrors={}
    }
    
  }
  GetGroupCodes()
  {
    debugger
    if(this.branch!=undefined)
    {
      this.auctionservice.GetGroupCodes(this.branch,this.date).subscribe(json=>{
        this.groupcodedetails=json
      })
    }
     
    
  
  }
  GroupChange(event)
  {
    if(event!=undefined)
    {
      this.groupcode=event.groupcode
    }
    
  }
  generatereport()
  {
    let isValid: boolean = true;
    if(this.checkValidations(this.AuctionDataReport,isValid))
    {
      this.auctionservice.GetPrizedSubscribersData(this.branch,this.groupcode,this.date).subscribe(json=>{
      this.prizedsubscribersdata=json
    
    console.log(json);
      })
    }
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {
      return false;
    }
    return isValid;
  }

  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {
    try {

      let formcontrol;
      formcontrol = formGroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.AuctionDataReporterrors[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;
            for (const errorkey in formcontrol.errors) {
              if (errorkey) {
                let lablename;
                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this._CommonService.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.AuctionDataReporterrors[key] += errormessage + ' ';
                isValid = false;
              }
            }
          }
        }
      }
    }
    catch (e) {
      return false;
    }
    return isValid;
  }
  BlurEventAllControll(fromgroup: FormGroup) {
    try {
      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })
    }
    catch (e) {
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {
    try {
      let formcontrol;
      formcontrol = fromgroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }
    }
    catch (e) {
      return false;
    }
  }
}
