import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { DatePipe } from '@angular/common';
import firebase from 'firebase';
import { LivebiddingService } from 'src/app/Services/livebidding.service';
import { CommonService } from 'src/app/Services/common.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { isNullOrEmptyString } from '@progress/kendo-angular-grid/dist/es2015/utils';
import { isNullOrUndefined } from 'util';
import { HostListener } from '@angular/core';
import { getuid } from 'process';
import { Guid } from 'guid-typescript';
declare let $: any;
@Component({
  selector: 'app-ticket-selection',
  templateUrl: './ticket-selection.component.html',
  styles: []
})
export class TicketSelectionComponent implements OnInit {
  [x: string]: any;
  ticketselectionform: FormGroup;
  groupcode: any;
  subscriberbidoffer:any;
  ticketdetails: any = []
  auctionerdetails: any= [];
  hallname: any;
  meetingid: any;
  auctiontime: any;
  checkeditems: any = []
  dueparticipants:any={}
  enterredvalue: any;
  branchid: any;
  ticketno: any;
  duedisable: boolean = false
  bidofferdisable: boolean = false
  branchname: any;
  referencevalidation: boolean = false;
  maxbidoffervalidation: boolean = false
  comments: any;
  maxbidoffer: any;
  duecheckeditems: any = [];
  username: string;
  bidoffergroupcode: any;
  auctioneer: any;
  auctionmonth: any;
  branchdata: any;
  bidbranchname: any;
  today: any;
  showstartauction:boolean
  mindiscountamount: any;
  maxdiscountamount: any;
  bidoffervalue: any;
  maxbidamount:any;
  activeclass:any;
  Maxbidofferform: FormGroup
  constructor(private activatedroute: ActivatedRoute, private hallallotmentservice: HallAllotmentService,
    private datepipe: DatePipe, private livebiddingservice: LivebiddingService,
    private router: Router, private commonservice: CommonService, private formBuilder: FormBuilder) {
    this.today = new Date().toLocaleString("en-US", {
      timeZone: "Asia/Kolkata"
    })
  }

  ngOnInit() {
    debugger;

    this.ticketselectionform = this.formBuilder.group({
      Reference: [''],
      comments: [''],
    });
    this.Maxbidofferform = this.formBuilder.group({
      maxbidoffer: [''],

    });
    //let routeParams = this.activatedroute.snapshot.queryParamMap.get('id'); 
    let routeParams = this.activatedroute.queryParams.subscribe(params => {
      debugger;
      // Defaults to 0 if no query param provided.
      this.groupcode = atob(params['id']);
          this.showstartauction=false
      console.log(params['meetingid'])
    });
    console.log("routeParams", this.groupcode);
    this.branchdata = this.hallallotmentservice.GetBranchDetails();
    this.Getticketnumbers(this.groupcode)
    setInterval(()=>{this.Timecalculation()},1000)
    this.bidoffergroupcode="";
    this.bidbranchname="";
    this.auctionmonth="";
  if(this.bidbranchname!="" && this.bidoffergroupcode!="" && this.auctionmonth!="")
  {
    setInterval(()=>{this.Getmaxbidoffer()},1000)
  }
  
    console.log(this.branchdata)
    console.log("today",this.today)
    console.log(new Date())

  }
  Getticketnumbers(groupcode) {
    debugger
    this.hallallotmentservice.TicketDetails(groupcode, this.branchdata.branchname).subscribe(json => {
      debugger;
      this.ticketdetails = json
      let maxbidamount= Math.max(...this.ticketdetails.map(({ subscriberbidoffer }) => subscriberbidoffer))
      this.ticketdetails.filter(function (fn) { fn.isenabledue = fn.due == 0 ? true : false, fn.isenablebidoffer = fn.due > 0 ? true : false ,
      fn.activeclass=(fn.subscriberbidoffer == maxbidamount && fn.subscriberbidoffer!=0)?"active":""});
      console.log("ticket details", this.ticketdetails)
      this.hallname = this.ticketdetails[0].auctionHall
      this.groupcode = this.ticketdetails[0].groupcode
      this.auctiontime = this.ticketdetails[0].auctionstartTime
      this.meetingid = this.ticketdetails[0].meetingId
      this.branchid = this.ticketdetails[0].branchId
      this.branchname = this.ticketdetails[0].branchname
     


    })
  }
  Timecalculation() {
    let auctiondate = new Date(this.ticketdetails[0].auctionstartDate)


    this.today = new Date();
    var difference = auctiondate.getTime() - this.today.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    

    if (resultInMinutes <= 0) {
          this.showstartauction=true
    }

  }
  BidOffer(i) {
    debugger
    console.log("i", i)
    this.bidoffervalue = ""
    this.maxbidoffervalidation = false
    this.username = i.subscriberName
    this.ticketno = i.ticketNo
    this.bidoffergroupcode = i.groupcode
    this.bidbranchname = i.branchname
    this.auctionmonth = i.auctmonth
    this.auctioneer = i.auctioneer
    this.subscriberbidoffer=i.subscriberbidoffer

    this.hallallotmentservice.GetMaxbidoffer(this.bidoffergroupcode, this.bidbranchname, this.auctionmonth).subscribe(json => {
      console.log("json", json)
      if (json != null) {
        debugger

        this.maxbidoffer = json.maxbidoffer == null ? 0 : json.maxbidoffer
        this.mindiscountamount = json.minbdiscountamt
        this.maxdiscountamount = json.maxdiscountamt
        console.log("maxbidoffer", this.mindiscountamount)
        $("#Bidofferparticipants").modal('show');

      }
    })
    

  }
Getmaxbidoffer()
{
  this.hallallotmentservice.GetMaxbidoffer(this.bidoffergroupcode, this.bidbranchname, this.auctionmonth).subscribe(json => {
    console.log("json", json)
    if (json != null) {
    

      this.maxbidoffer = json.maxbidoffer == null ? 0 : json.maxbidoffer
      this.mindiscountamount = json.minbdiscountamt
      this.maxdiscountamount = json.maxdiscountamt
      // this.ticketdetails.filter(function (fn) {
      //   fn.activeclass=this.mindiscountamount==fn.subscriberbidoffer? "active":""});
     
    }
  })
}
  DueParticipants(i) {
    debugger

    console.log("data", i)
    this.username = i.subscriberName
    this.ticketno = i.ticketNo


    this.bidoffergroupcode = i.groupcode
    this.bidbranchname = i.branchname
    this.auctionmonth = i.auctmonth
    this.auctioneer = i.auctioneer
    // data.logindate=this.datepipe.transform(new Date(),'yyyy-MM-dd');
    // data.logintime=this.datepipe.transform(new Date(),'HH:mm');
    i.subscribertype = 'walkin client';
    i.meetingId = this.meetingid;
    i.referenceno = "";
    i.comments = "";
    if (i.due > 0) {
      this.duecheckeditems = i;
      this.dueparticipants=i;
      this.referencevalidation = false;
      this.ticketselectionform.controls.Reference.setValue('');
      this.ticketselectionform.controls.comments.setValue('');
      $("#Dueparticipants").modal('show');
    }
    else {
      this.duecheckeditems = [];
      this.referencevalidation = false;
      this.ticketselectionform.controls.Reference.setValue('');
      this.ticketselectionform.controls.comments.setValue('');
      this.checkeditems.push(i)
    }
  }
  startauction() {
    firebase.database().ref('auctioneer/').orderByChild('groupcode').equalTo(this.groupcode).once('value')
    .then(function(snapshot) {
      snapshot.forEach(function(childSnapshot) {
        console.log(childSnapshot.val());
        childSnapshot.ref.update({'status':'N'});
      });
    });
    setTimeout(()=>{  
        this.livebiddingservice.StartAuction(this.groupcode, this.hallname, this.branchdata.branchname).subscribe(json => {
          console.log("result", json)
          debugger;
          let saved = json["isSaved"];
          if (saved) {
            let MaxbidReacedsubscribers = [];
            localStorage.removeItem('groupdetails');
            localStorage.clear();
            MaxbidReacedsubscribers = json["maxbidofferSubcriberList"];
            for (let i = 0; i < MaxbidReacedsubscribers.length; i++) {
              let maxbidparticipants = MaxbidReacedsubscribers[i];
              ///        push Maxbid Reaced subscribers to chats///////////////
              MaxbidReacedsubscribers[i].livedate = this.datepipe.transform(MaxbidReacedsubscribers[i].currentdatettime, 'yyyy-MM-dd HH:mm:ss');
              MaxbidReacedsubscribers[i].livetime = this.datepipe.transform(MaxbidReacedsubscribers[i].currentdatettime, 'hh:mm:ss a');
              MaxbidReacedsubscribers[i].meetingId = this.meetingid;
              MaxbidReacedsubscribers[i].biddamount = MaxbidReacedsubscribers[i].mindiscountamt;
              const newMessage = firebase.database().ref('chats/').push();
              newMessage.set(MaxbidReacedsubscribers[i]);
              //   push Maxbid Reaced subscribers to userinfo/////////////
              maxbidparticipants.logindate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
              maxbidparticipants.logintime = this.datepipe.transform(new Date(), 'HH:mm');
              maxbidparticipants.subscribertype = 'online client';
              const newMessage2 = firebase.database().ref('userinfo/').push();
              newMessage2.set(maxbidparticipants);     
            }
            // let data=this.auctionerdetails;          
            //  data.groupcode=this.groupcode;
            //  data.branchname=this.branchdata.branchname;
            //  data.status='Y';
            //  var sessionid=Guid.create().toString();
            //  data.sessionvalue=sessionid;
            // const newMessage1 = firebase.database().ref('auctioneer/').push();
            // newMessage1.set(data);
            this.livebiddingservice.SetTicketDetails(this.groupcode, this.checkeditems,"Direct")
            //  window.open("http://localhost:4200/#/Livebidding",'popup','menubar=0,width=1800,height=1200');
            window.open("/#/Livebidding", "_blank", "resizable=no, toolbar=no, scrollbars=no, menubar=no, status=no, directories=no, location=no,width=1800,height=1200");
            let hallname = btoa(this.hallname + ',' + this.branchid + ',' + this.branchname)
            this.router.navigate(['/GroupSelection'], { queryParams: { id: hallname } });
          }
        })
      },1000)  
  }
  Referencechange() {
    let reference = this.ticketselectionform.controls.Reference.value;
    if (isNullOrEmptyString(reference) || isNullOrUndefined(reference)) {
      this.referencevalidation = true;
    }
    else {
      this.referencevalidation = false;
    }

  }
  BidOfferChange(event) {
    debugger
    console.log(event.target.value)
    this.bidoffervalue = event.target.value
    if(this.commonservice.removeCommasForEntredNumber(event.target.value)==this.subscriberbidoffer)
    {
      this.commonservice.showWarningMessage("bid offer already exists");
      this.Maxbidofferform.controls.maxbidoffer.setValue("");
    }
    else{
      this.enterredvalue = this.commonservice.removeCommasForEntredNumber(event.target.value)

      let maxbidoffer = this.Maxbidofferform.controls.maxbidoffer.value;
  
      if (isNullOrEmptyString(maxbidoffer) || isNullOrUndefined(maxbidoffer)) {
        this.maxbidoffervalidation = true;
      }
      else {
        this.maxbidoffervalidation = false;
      }
      if (this.enterredvalue == this.maxdiscountamount) {
        this.CalculationofBidoffer()
      }
      else if (this.enterredvalue <= this.mindiscountamount || this.enterredvalue > this.maxdiscountamount) {
        this.Maxbidofferform.controls.maxbidoffer.setValue("");
        this.commonservice.showWarningMessage("Amount should be in between Max. Discount Offered and Maximum Discount Allowed")
  
  
      }
      else {
  
        this.CalculationofBidoffer()
  
      }
    }
  


  }
  CalculationofBidoffer() {
    var lastDigit = String(this.enterredvalue).substr(-3);
    let lastdigits = parseInt(lastDigit)
    console.log("lastDigit", lastdigits);
    var x = lastdigits;
    var y = 100;
    var z = x % y;
    console.log("z", z)
    if (z == 0) {
      this.Maxbidofferform.controls.maxbidoffer.setValue(this.bidoffervalue)
    }
    else {
      this.Maxbidofferform.controls.maxbidoffer.setValue("");
     
      this.commonservice.showWarningMessage("Amount should be multiples of 100");
      this.maxbidoffervalidation=false
    }
  }
  AddreferenceNo() {
    debugger;
    let reference = this.ticketselectionform.controls.Reference.value;
    if (isNullOrEmptyString(reference) || isNullOrUndefined(reference)) {
      this.referencevalidation = true;
    }
    else {
      this.duecheckeditems.referenceno = reference;
                this.duecheckeditems.comments = this.ticketselectionform.controls.comments.value;
                this.checkeditems.push(this.duecheckeditems)
                this.dueparticipants.referenceno= reference;
                this.dueparticipants.comments = this.ticketselectionform.controls.comments.value;
        this.livebiddingservice.GetReferenceNocount(reference,this.bidbranchname).subscribe(json=>{
          
         
          if(json)
          {
            console.log(json);
            this.commonservice.showWarningMessage("Reference No. Already Existed")

          }
          else{
            this.livebiddingservice.SaveDueparticipants(this.dueparticipants).subscribe(json=>{
            if(json)
            {
              console.log(json)
             
               
                $("#Dueparticipants").modal('hide');
                this.Getticketnumbers(this.groupcode);
             
            }
        })
          }
       
        })
        
        

    

    }
  }

  SaveBidOffer() {
    let bidoffer = this.Maxbidofferform.controls.maxbidoffer.value;
    if (isNullOrEmptyString(bidoffer) || isNullOrUndefined(bidoffer)) {
      this.maxbidoffervalidation = true;
    }
    else {
      let maxbidoffer = this.commonservice.removeCommasForEntredNumber(this.Maxbidofferform.controls.maxbidoffer.value)
      let data = {
        branchname: this.bidbranchname, groupcode: this.bidoffergroupcode, ticketNo: this.ticketno,
        subscriberName: this.username, auctmonth: this.auctionmonth, maxbidoffer: maxbidoffer, auctioneer: this.auctioneer
      }
      console.log("saved data", data)
      this.hallallotmentservice.SaveMaxbidoffer(data).subscribe(json => {
        debugger;
        if (json) {
          $("#Bidofferparticipants").modal('hide');
          this.maxbidoffer = "";
          this.Maxbidofferform.controls.maxbidoffer.setValue("");
          this.maxbidoffervalidation = false
          this.Getticketnumbers(this.groupcode)
        }


      })

    }
  }
}
