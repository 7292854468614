import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CountdownModule,CountdownGlobalConfig, CountdownConfig  } from 'ngx-countdown';
import { IntlModule } from '@progress/kendo-angular-intl';
import { NumbersonlyDirective } from './Directives/numbersonly.directive';
import { AddressformatDirective } from './Directives/addressformat.directive';
import { CharactersonlyDirective } from './Directives/charactersonly.directive';
import { EmailpatternDirective } from './Directives/emailpattern.directive';
import { MycurrencyFormatterDirective } from './Directives/mycurrency-formatter.directive';
import { NewlineDirective } from './Directives/newline.directive';
import { TitlecasewordDirective } from './Directives/titlecaseword.directive';
import { EmailFormatDirective } from './Directives/emailformat.directive';
import { EnterpriseNameFormatDirective } from './Directives/enterprisenameformat';
import { TwoDigitDecimaNumberDirective } from './Directives/two-digit-decima-number.directive';
import { ThreeDigitDecimaNumberDirective } from './Directives/three-digit-decima-number.directive';
import { InitCapDirective } from './Directives/InitCap.directive';
import { AppComponent } from './app.component';
import { FilterPipeModule } from 'ngx-filter-pipe'
import { DashboardComponent } from './UI/Home/dashboard.component';
import { NavigationComponent } from './UI/Home/navigation.component';
import { CookieService } from 'ngx-cookie-service';
import { MycurrencypipePipe } from './Pipes/mycurrencypipe.pipe';
import { RoundecimalDirective } from './Directives/roundecimal.directive';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { ValidationMessageComponent } from './UI/Common/validation-message/validation-message.component';
import { TimePickerModule } from '@progress/kendo-angular-dateinputs';
import { ButtonDoubleClickDirective } from './Directives/button-double-click.directive';
import { AlphaNumericDirective } from './Directives/alpha-numeric.directive';
import { AlphanumericcharsonlyDirective } from './Directives/alphanumericcharsonly.directive';
import { appAlphanumericwithSpecialCharactersDirective } from './Directives/AlphaNumericWithSpecialCharacters.directive';

import { TimeMaskDirective } from './Directives/time-mask.directive'

///
import { IfsccodevalidatorDirective } from './Directives/ifsccodevalidator.directive';

//import { ContextMenuModule } from '@progress/kendo-angular-menu';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { ContextMenuModule } from 'ngx-contextmenu';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';


import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { AddMenuComponent } from './UI/Settings/add-menu/add-menu.component';
import { UserRightsComponent } from './UI/Settings/user-rights/user-rights.component';



import { NumberToWordsPipe } from './Pipes/number-to-words.pipe';
import { UsersviewComponent } from './UI/Settings/Users/usersview/usersview.component';
import { UsersregistrationComponent } from './UI/Settings/Users/usersregistration/usersregistration.component';
import { LoginComponent } from './login/login.component';
import { UserLoginComponent } from './UI/Settings/Users/user-login/user-login.component';
import { JwtInterceptor } from './Services/Settings/Users/_helpers/jwt.interceptor';
import { ErrorInterceptor } from './Services/Settings/Users/_helpers/error.interceptor';
import { AuthGuard } from './Services/Settings/Users/_helpers/auth.guard';


import { AutoFocusDirective } from './Directives/auto-focus.directive';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';


import { RemoveZeroDirective } from './Directives/remove-zero.directive';

import { NgSelectModule } from '@ng-select/ng-select';
import { ZeroDirective } from './Directives/zero.directive';
import { DecimalwithcurrencyformatDirective } from './Directives/decimalwithcurrencyformat.directive';


import { CurrencypipewithdecimalPipe } from './Pipes/currencypipewithdecimal.pipe';

import { NegativevaluePipe } from './Pipes/negativevalue.pipe';
import { MutipleDigitDecimalNumberDirective } from './Directives/mutiple-digit-decimal-number.directive';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';






import { from } from 'rxjs';


import { UppercaseDirective } from './Directives/uppercase.directive';
import { TestComponent } from './UI/Home/test.component';
import { LiveBiddingComponent } from './UI/User/live-bidding/live-bidding.component';
import { HallAllotmentComponent } from './UI/Admin/hall-allotment/hall-allotment.component';
import { NgxWheelModule } from 'ngx-wheel';
import { AuthenticationComponent } from './UI/Admin/authentication/authentication.component';
import { GroupSelectionComponent } from './UI/Admin/group-selection/group-selection.component';
import { TicketSelectionComponent } from './UI/Admin/ticket-selection/ticket-selection.component';
import { DrawspinnerComponent } from './UI/User/live-bidding/drawspinner.component';
import { SortingPipePipe } from './Pipes/sorting-pipe.pipe';
import { ReviewerComponent } from './UI/Admin/Reviewer/reviewer/reviewer.component';
import { AuctionHistoryReportComponent } from './UI/Reports/AuctionHistoryReport/auction-history-report.component';
import { CompanydetailsComponent } from './UI/Common/companydetails/companydetails.component';
import { PrizedsubscribersComponent } from './UI/Reports/prizedsubscribers.component';
import { ReAuctionComponent } from './UI/Admin/ReAuction/re-auction.component';
import { EmployeeComponent } from './UI/Admin/employee.component';
import { RescheduleComponent } from './UI/Admin/Reschedule/reschedule.component';
import { RemovespaceDirective } from './Directives/removespace.directive';
import { ChitaucthorizationComponent } from './UI/Reports/ChitAuthorization/chitaucthorization.component';
import { MockDataComponent } from './UI/Admin/Mock-Data/mock-data.component';
import { ChitunauthorizationComponent } from './UI/Reports/ChitUnAuthorization/chitunauthorization.component';
import {ConfirmBoxConfigModule, NgxAwesomePopupModule} from '@costlydeveloper/ngx-awesome-popup';


export function getDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'DD/MM/YYYY'
    });
}
export function countdownConfigFactory(): CountdownConfig {
    return {};
  }
const appRoutes: Routes = [


    { path: '', component: UserLoginComponent },
   
   
    {path:'Authentication',component:AuthenticationComponent},
    { path: 'Test', component: TestComponent},
    { path: 'Livebidding', component: LiveBiddingComponent },
    { path: 'Livebidding/:id', component: LiveBiddingComponent },

    {
       
        path: '', component: NavigationComponent,
        children: [
            {path:'auctiondata',component:PrizedsubscribersComponent,canActivate: [AuthGuard]},
            {path:'chitauthorization',component:ChitaucthorizationComponent,canActivate: [AuthGuard]},
            {path:'chitUnauthorization',component:ChitunauthorizationComponent,canActivate: [AuthGuard]},
            {path:'auctionhistory',component:AuctionHistoryReportComponent,canActivate: [AuthGuard]},
            {path:'mockdata',component:MockDataComponent,canActivate: [AuthGuard]},
            {path:'Hallallotment',component:HallAllotmentComponent,canActivate: [AuthGuard]},
            {path:'Reviewer',component:ReviewerComponent,canActivate: [AuthGuard]},
            {path: 'Dashboard', component: DashboardComponent,canActivate: [AuthGuard]},
            {path:'Reauction',component:ReAuctionComponent,canActivate: [AuthGuard]},
            {path:'Reschedule',component:RescheduleComponent,canActivate: [AuthGuard]},
             {path:'Employee',component:EmployeeComponent,canActivate: [AuthGuard]},
            {path:'GroupSelection',component:GroupSelectionComponent,canActivate: [AuthGuard]},
            {path:'GroupSelection/:id',component:GroupSelectionComponent,canActivate: [AuthGuard]},
            {path:'TicketSelection',component:TicketSelectionComponent,canActivate: [AuthGuard]},
           {path:'TicketSelection/:id',component:TicketSelectionComponent,canActivate: [AuthGuard]},
            { path: 'AddMenu', component: AddMenuComponent, canActivate: [AuthGuard] },
            { path: 'AddMenu/:id', component: AddMenuComponent },
            { path: 'UserRights', component: UserRightsComponent, canActivate: [AuthGuard] },
            { path: 'UserRights/:id', component: UserRightsComponent },
            { path: 'UsersView', component: UsersviewComponent },
            { path: 'UsersRegistration', component: UsersregistrationComponent },
    

        ]
        

    },
    


];
@NgModule({
    declarations: [
        AppComponent,
        DashboardComponent,
        NavigationComponent,

        NumbersonlyDirective,
        UserLoginComponent,
        AddressformatDirective,
        CharactersonlyDirective,
        UppercaseDirective,
        EmailpatternDirective,
        EmailFormatDirective,
        EnterpriseNameFormatDirective,
        ThreeDigitDecimaNumberDirective,
        TwoDigitDecimaNumberDirective,
        MycurrencyFormatterDirective,
        UppercaseDirective,
        NewlineDirective,
        TitlecasewordDirective,

        AddMenuComponent,
        UserRightsComponent,

        InitCapDirective,


        MycurrencypipePipe,
        IfsccodevalidatorDirective,
        AutoFocusDirective,
        RoundecimalDirective,
        ValidationMessageComponent,
        ButtonDoubleClickDirective,
        AlphaNumericDirective,
        AlphanumericcharsonlyDirective,
        appAlphanumericwithSpecialCharactersDirective,
        TimeMaskDirective,
        NumberToWordsPipe, AddMenuComponent, UserRightsComponent, UsersviewComponent, UsersregistrationComponent,
        LoginComponent,
        UserLoginComponent,
        RemoveZeroDirective, ZeroDirective,
        DecimalwithcurrencyformatDirective, CurrencypipewithdecimalPipe,
        NegativevaluePipe,
        MutipleDigitDecimalNumberDirective,
        TestComponent,
        LiveBiddingComponent,
        HallAllotmentComponent,
        AuthenticationComponent,
        GroupSelectionComponent,
        TicketSelectionComponent,
        DrawspinnerComponent,
        SortingPipePipe,
        ReviewerComponent,
        AuctionHistoryReportComponent,
        CompanydetailsComponent,
        PrizedsubscribersComponent,
        ReAuctionComponent,
        EmployeeComponent,
        RescheduleComponent,
        RemovespaceDirective,
        ChitaucthorizationComponent,
        MockDataComponent,
        ChitunauthorizationComponent,
  
    ],
    imports: [
        FormsModule,
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        Ng4LoadingSpinnerModule.forRoot(),
        HttpClientModule,
        AmazingTimePickerModule,
        TimePickerModule,
        ReactiveFormsModule,
        ImageCropperModule,
        DateInputsModule,
        BrowserAnimationsModule,
        GridModule,
        NgSelectModule,
      
        IntlModule,
        NgxWheelModule,
        NgMultiSelectDropDownModule.forRoot(),
        ExcelModule,
        NgxAwesomePopupModule.forRoot(),
        ConfirmBoxConfigModule.forRoot(),
        PDFModule,
        CountdownModule,
        FilterPipeModule,
        ContextMenuModule.forRoot({
            useBootstrap4: true,
        }), TreeViewModule, NgxDatatableModule,
        NgxLoadingModule.forRoot({}),
        BsDatepickerModule.forRoot(),
        ToastrModule.forRoot({
            // timeOut: 1000,
            positionClass: 'toast-top-right',
            closeButton: true,
            preventDuplicates: true
        }),


        RouterModule.forRoot(appRoutes, { useHash: true, scrollPositionRestoration: 'enabled' }),


        PDFExportModule, ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' })

    ],
    providers: [
        { provide: BsDatepickerConfig, useFactory: getDatepickerConfig },
        { provide: CountdownGlobalConfig, useFactory: countdownConfigFactory },
        { provide: LOCALE_ID, useValue: "en-IN" },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        CookieService, DatePipe, TitleCasePipe, NegativevaluePipe],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
