import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import firebase from 'firebase';
import { NgxWheelComponent } from 'ngx-wheel';
import { CommonService } from 'src/app/Services/common.service';

// import { AngularFireDatabase } from '@angular/fire/database';
import { FirebaseService } from 'src/app/Services/firebase.service';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { LivebiddingService } from 'src/app/Services/livebidding.service';
import { isNullOrUndefined } from 'util';


let $:any;
@Component({
  selector: 'app-drawspinner',
  templateUrl: './drawspinner.component.html',
  styles: []
})
export class DrawspinnerComponent implements OnInit {
  chatreference=firebase.database().ref('chats/')
  @Input() usertype: any;
  @Input() branch: any;
  @Input() auctionmonth: any;
  @Input() groupcode:any;
   meetingid: any;
  @Output() emitevent = new EventEmitter()
  idToLandOn: any;
  maxamount: number;
  chats = [];
  disablespin:boolean
  spinstatus:boolean=false;
  drawticketslength:any;
  disabledforButtons: boolean = true
  drawtickets=[]
  key:any;
  items: any[];
  @ViewChild(NgxWheelComponent, { static: false }) wheel;
  drawid: any;
  auctioneer: any;
  branchdata: any;
  constructor(private commonservice:CommonService,public datepipe: DatePipe,
    private livebiddingservice:LivebiddingService,private hallallotmentservice:HallAllotmentService
    ) {
    this.getspindata();
   }

  ngOnInit() {
    debugger;
    this.branchdata=this.hallallotmentservice.GetBranchDetails();
   console.log("usertype",this.usertype)
   console.log("branch",this.branch)
   console.log("auctionmonth",this.auctionmonth)
    this.usertype=='walkin-client'?this.disablespin=false:this.disablespin=true
    this.disabledforButtons=this.usertype=='walkin-client'?true:false;
    if(this.usertype=='walkin-client'){
      this.disabledforButtons=this.branchdata.pType=='reviewer'?false:true;
    }

    this.drawtickets=this.livebiddingservice.getDrawticketnumbers();
    this.idToLandOn=this.drawtickets[Math.floor(Math.random() * this.drawtickets.length)];
    let meetingdata=this.livebiddingservice.getMeetingid();
    this.meetingid=meetingdata.meetingid; 
    this.auctioneer=meetingdata.Auctioneername;
    this.drawticketslength=this.drawtickets.length
    this.SetWheel();
   this.GetIdtolandon();
   this.getspindata();
  }
  GetIdtolandon(){
    firebase.database().ref('ShowSpinner/').orderByChild('meetingId').equalTo(this.meetingid).once('value', snapshot => {
    let data=[];
    debugger;
    data = snapshot.exists() ? this.commonservice.ConvertfirebaseDataToArray(snapshot) : [];
    data = data.filter(item => item.meetingId == this.meetingid)
     this.idToLandOn=data[0].idToLandOn
    })
  }
  SetWheel(){
   
    // const colors = ['#FF0000', '#000000']
    const colors = ['#3f51b5','#673ab7','#9c27b0"','#e91e63','#f44336','#ff5722','#ff9800','#ffc107','#ffeb3b','#8bc34a','#4caf50','#009688','#00bcd4','#03a9f4','#2196f3']
  
    debugger;
    let j=0;
    this.items=[];
    for(let i=0;i<this.drawtickets.length;i++){
      debugger;
      if(isNullOrUndefined(colors[j])){
    j=0;
      }
let data={fillStyle:colors[j],text: `${this.drawtickets[i]}`,id: this.drawtickets[i],textFillStyle: 'white',
textFontSize: '16'}
this.items.push(data)
j=j+1;
    }
  //   this.items = this.drawtickets.map((value,index) => ({
      
  //     fillStyle:colors[value % 2],
  //     text: `${value}`,
  //     id: value,
  //     textFillStyle: 'white',
  //     textFontSize: '16'
  //   }))
  console.log("colorscode",this.items)
  }
  before()
  {

  }
  after(text)
  {
    console.log("spin",text)
    // firebase.database().ref('spinstart/').child(this.key).remove();
    firebase.database().ref('spinstart/').child(this.key).remove();
    this.emitevent.emit(text)
    debugger   
  }
  Spinwheel(){
    debugger;
    this.spinstatus=true;
    let data={spinstartdate:this.datepipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),spinstatus:true,meetingid:this.meetingid}
     const newMessage = firebase.database().ref('spinstart/').push();
   newMessage.set(data);
  }
  async  spin(){
    await new Promise(resolve => setTimeout(resolve, 0));
    this.wheel.spin()
  }
  getspindata(){
    if(!isNullOrUndefined(this.meetingid)){
    firebase.database().ref('spinstart/').on('value', resp => {
      debugger;
      let data=resp.exists()?this.commonservice.ConvertfirebaseDataToArray(resp):[];
      data=data.filter(item=>item.meetingid==this.meetingid);
      this.spinstatus=data.length>0?data[0].spinstatus:false;
      if(this.spinstatus){ 
        this.key=data[0].key;
        this.spin();
      }
    });
  }
  }
}
