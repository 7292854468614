import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/Services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HallAllotmentService } from 'src/app/Services/Hall-Allotment/hall-allotment.service';
import { isNullOrUndefined } from '@swimlane/ngx-datatable';
import { timingSafeEqual } from 'crypto';
declare let $: any;
@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styles: []
})
export class EmployeeComponent implements OnInit {

  constructor(private _CommonService:CommonService,private fb:FormBuilder,
    private hallallotment:HallAllotmentService) { }
  showbutton:boolean=false
  Employeeform:FormGroup;
  Employeeformerrors:any;
  branchdeatils:any=[];
  branchname:any;
  Employeedata:any=[];
  mobileno:any;
  ngOnInit() 
  {
    this.GetFormgroup();
    this.GetBranchNames()
    this.BlurEventAllControll(this.Employeeform)
  }

  GetFormgroup()
  {
    this.Employeeformerrors={}
    this.Employeeform = this.fb.group({
      employeename : ['',Validators.required],
      mobileno : ['',[Validators.required, Validators.minLength(10)]],
      branchname :[''],
      gender :['M',Validators.required],
      desigination :[''],
      department :['']


    });
  }
  GetBranchNames()
  {
     this.hallallotment.GetBranchNames().subscribe(json=>{
         if(json)
         {
           console.log(json)
           this.branchdeatils=json
         }
     })
  }
  AddEmployee()
  {
    if(this.branchname=="")
    {
      this._CommonService.showWarningMessage("please select a branch")
    }
    else{
      this.Employeeform.controls.employeename.setValue("");
      this.Employeeform.controls.mobileno.setValue("");
      this.Employeeformerrors={}
      $("#addemployeemodal").modal('show');
    }
   
  }
  checkValidations(group: FormGroup, isValid: boolean): boolean {
    try {
      Object.keys(group.controls).forEach((key: string) => {
        isValid = this.GetValidationByControl(group, key, isValid);
      })
    }
    catch (e) {
      return false;
    }
    return isValid;
  }

  GetValidationByControl(formGroup: FormGroup, key: string, isValid: boolean): boolean {
    try {

      let formcontrol;
      formcontrol = formGroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.checkValidations(formcontrol, isValid)
        }
        else if (formcontrol.validator) {
          this.Employeeformerrors[key] = '';
          if (formcontrol.errors || formcontrol.invalid || formcontrol.touched || formcontrol.dirty) {

            let errormessage;
            for (const errorkey in formcontrol.errors) {
              if (errorkey) {
                let lablename;
                lablename = (document.getElementById(key) as HTMLInputElement).title;
                errormessage = this._CommonService.getValidationMessage(formcontrol, errorkey, lablename, key, '');
                this.Employeeformerrors[key] += errormessage + ' ';
                isValid = false;
              }
            }
          }
        }
      }
    }
    catch (e) {
      return false;
    }
    return isValid;
  }
  BlurEventAllControll(fromgroup: FormGroup) {
    try {
      Object.keys(fromgroup.controls).forEach((key: string) => {
        this.setBlurEvent(fromgroup, key);
      })
    }
    catch (e) {
      return false;
    }
  }
  setBlurEvent(fromgroup: FormGroup, key: string) {
    try {
      let formcontrol;
      formcontrol = fromgroup.get(key);
      if (formcontrol) {
        if (formcontrol instanceof FormGroup) {
          this.BlurEventAllControll(formcontrol)
        }
        else {
          if (formcontrol.validator)
            fromgroup.get(key).valueChanges.subscribe((data) => { this.GetValidationByControl(fromgroup, key, true) })
        }
      }
    }
    catch (e) {
      return false;
    }
  }
  BranchChange(event)
  {
    debugger;
    if(event!=undefined)
    {
      this.branchname=event.branchname;
      this.Employeeform.controls.branchname.setValue(this.branchname)
      this.GetGridData()
    }
    else{
      this.Employeedata=[];
      this.branchname=""
    }
   
  }
  GetGridData()
  {
    this.hallallotment.GetEmployeeDetails(this.branchname).subscribe(json=>{
      debugger
      this.Employeedata=json;
      if(this.Employeedata.length==0)
      {
        this.showbutton=true
      }
      else{
        this.showbutton=false
      }
     
      
      
    })
  }
  Mobilenumberchange(event)
  {
    debugger;
    this.mobileno=event.target.value;
    this.hallallotment.checkMobileNumberExist(this.branchname,this.mobileno).subscribe(json=>{
      console.log(json);
      if(json)
      {
        this._CommonService.showWarningMessage("Mobile no. already existed");
        this.Employeeform.controls.mobileno.setValue("")

      }
   
    })
  }
  Save()
  {
    debugger
    let isValid: boolean = true;
    if(this.checkValidations(this.Employeeform,isValid))
    {
      let data=JSON.stringify(this.Employeeform.value);
      this.hallallotment.SaveEmployee(data).subscribe(json=>{
        console.log(json);
        if(json)
        {
          $("#addemployeemodal").modal('hide');
          this.GetGridData()
        }
      })
    }
  }
}
